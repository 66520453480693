import styled from 'styled-components';

export const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 3em;
  table {
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;

    ul li {
      list-style-type: decimal;
    }
  }
`;
export const CenterLayout = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const AddQuestionButton = styled.button`
  width: 208px;
  height: 39px;
  border-radius: 10px;
  background: #ba0c2f;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  border: none;
  margin: 20px 0 30px;
`;
export const ButtonWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ButtonIcon = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
// ModalBox
export const FormTitle = styled.h1`
  font-size: 28px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin: 10px 0 30px;
`;

export const Form = styled.form`
  max-width: 340px;
  width: 100%;
  max-height: 560px;
  height: 100%;
  padding: 1em 2em;
  background-color: #ba0c2f;
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Input = styled.input`
  max-height: 40px;
  padding: 8px 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  border: none;
  margin: 10px 0;
`;

export const InputInnerWrapper = styled.div``;

export const InputCheckbox = styled.input`
  width: 50px;
  ::after {
    content: 'Correcta';
    color: #ffffff;
  }
`;

export const Label = styled.label`
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  width: 100%;
`;

export const Error = styled.p`
  height: 25px;
  font-size: 12px;
  font-weight: 800;
  line-height: 14px;
  color: #ffffff;
`;

export const FormButton = styled.button`
  width: 208px;
  height: 39px;
  border-radius: 10px;
  background: #ba0c2f;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin: 20px 0 30px;
  border: 1px solid #ffffff;
`;

export const ApproveButton = styled.button`
  border-radius: 10px;
  background: #ba0c2f;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0em;
  padding: 0 10px;
  text-align: center;
  color: #ffffff;
  border: none;
  margin: 5px;
  &:disabled {
    background-color: grey;
  }
`;

// Time Per Question field

export const TimePerQuestionForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  div{
    display:flex ;
  }
  label {
    padding: 8px 5px 0 0;
  }
  input {
    width: 40px;
    margin-right: 5px;
  }
  button {
    margin-top: 5px;
    border-radius: 10px;
    background: #ba0c2f;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0em;
    padding: 0 10px;
    text-align: center;
    color: #ffffff;
    border: none;
  }
`;

export const NumberOfQuestionForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  div{
    display:flex ;
  }
  label {
    padding: 8px 5px 0 0;
  }
  input {
    width: 40px;
    margin-right: 5px;
  }
  button {
    margin-top: 5px;
    border-radius: 10px;
    background: #ba0c2f;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0em;
    padding: 0 10px;
    text-align: center;
    color: #ffffff;
    border: none;
  }
`;


export const ViewButton = styled.button`
  border-radius: 10px;
  background: #ba0c2f;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
`;

export const ViewTicketWrapper = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 500px;
  width: 500px;
  border-radius: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgTicket = styled.img`
  max-height: 500px;
  height: 100%;
  max-width: 500px;
  width: 100%;
  object-fit: contain;
`;

export const InformationWrapper = styled.div`
  width: 1000px;
  height: 600px;
  background-color: #ffffff;
  border-radius: 33px;
  display: flex;
  flex-direction: column;
  padding: 2em;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: auto;
`;

export const ItemWrapper = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  span {
    font-weight: 800;
    margin-right: 1em;
  }
`;

export const LoaderWrapper = styled.div`
  margin: 0 5px;
  display: flex;
`;
