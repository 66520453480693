import styled from 'styled-components';

export const Layout = styled.div`
  max-width: 1012px;
  width: 100%;
  max-height: 600px;
  height: 100%;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ba0c2f;
  border-radius: 23px;
  @media (max-width: 900px) {
    max-height: 600px;
    max-width: 100%;
    width: calc(100% - 32px);
    padding: 0 16px;
    overflow: auto;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MainTitle = styled.h1`
  font-family: 'ITC Caslon 224 Std';
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  top: 40px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const SecondsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 33px auto 0;
`;

export const SecondsTitle = styled.p`
  font-size: 30px;
  font-weight: 400;
  line-height: 28.71px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
`;

export const ProgressBar = styled.div`
  width: 230px;
  height: 11px;
  background-color: transparent;
  position: relative;
  margin: 0 auto;
  box-shadow: 0px 0px 4px 0px #96585b80 inset;
  border-radius: 6px;
  margin: 15px 0 42px;
`;

export const ProgressFiller = styled.div<{ width: number }>`
  height: 100%;
  width: ${(props) => props.width}%;
  background-color: #dd434c;
  transition: width 0.3s linear;
  border-radius: 6px;
`;

export const Question = styled.h1`
  font-size: 30px;
  font-weight: 800;
  line-height: 28.71px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 36px;
  max-width: 550px;
  text-transform: capitalize;
  @media (max-width: 900px) {
    font-size: 25px;
    line-height: 24px;
  }
`;

export const Button = styled.button`
  width: 338px;
  min-height: 65px;
  height:100% ;
  overflow:hidden;
  box-shadow: 2px 2px 2px 0px #ad142166;
  box-shadow: -2px -2px 3px 0px #fd515f66;
  font-size: 30px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  background-color: #ba0c2f;
  margin-bottom: 22px;
  border: none;
  border-radius: 36px;
  transition: 0.3s;
  display:flex ;
  justify-content:center ;
  align-items:center ;
  &:hover, &:active {
    background-color: #ffffff;
    color: #ba0c2f;
  }
  @media (max-width: 900px) {
    min-width: 270px;
    width: 100%;
    font-size: 30px;
    line-height: 29px;
  }
`;

export const AnswersWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const QuestionBanner = styled.div`
  font-size: 20px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 200px;
  height: 20px;
  margin: 0 auto;
  color: #ffffff;
  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 19px;
  }
`;

export const GoBack = styled.button`
  position: absolute;
  top: 39px;
  left: 41px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  @media (max-width: 900px) {
    top: 0px;
    left: -30px;
    scale: 0.5;
  }
`;

export const CenterLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
