import React from 'react';
import MainLayout from '../../Layouts/MainLayout';
import {
  MainTitle,
  Step,
  Wrapper,
  Paragraph,
  ParagraphWrapper,
  ReciboImg,
  LaptopImg,
} from './styled';
import Recibo from '../../assets/recibo.png';
import Laptop from '../../assets/laptop.png';
import { RegisterButton } from '../../ui/common';
import { useGlobalContext } from '../../context/GlobalContext';

const Steps2 = ({ onMouseMove }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();
  return (
    <MainLayout>
      <RegisterButton onClick={() => false}>
        Registrar nuevo ticket
      </RegisterButton>
      <MainTitle>Participar es muy fácil:</MainTitle>
      <Wrapper>
        <Step>
          <ReciboImg src={Recibo} loading='lazy' />
          <ParagraphWrapper>
            <Paragraph>3. Registra tu ticket de compra.</Paragraph>
          </ParagraphWrapper>
        </Step>
        <Step>
          <LaptopImg src={Laptop} loading='lazy' />
          <ParagraphWrapper>
            <Paragraph>
              4. Participa en nuestra trivia, solo tienes que contestar
              correctamente las 6 preguntas en el tiempo establecido y gana
              premios al instante.
            </Paragraph>
          </ParagraphWrapper>
        </Step>
      </Wrapper>
    </MainLayout>
  );
};

export default Steps2;
