import MainLayout from '../../Layouts/MainLayout';
import { RegisterButton } from '../../ui/common';
import { Wrapper, Img, Step } from './styled';
import Collarin from '../../assets/collarin.png';
import { useGlobalContext } from '../../context/GlobalContext';

const Step2M = ({ onMouseMove }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();

  return (
    <MainLayout>
      <RegisterButton onClick={() => false}>
        Registrar nuevo ticket
      </RegisterButton>
      <Wrapper>
        <Img src={Collarin} loading='lazy' />
        <Step>
          2. Escanea el código QR del collarín o entra a:
          <span>www.minuto90.mx</span>
        </Step>
      </Wrapper>
    </MainLayout>
  );
};

export default Step2M;
