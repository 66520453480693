import { useState, useEffect } from "react";
import MainLayout from "../../Layouts/MainLayout";
import { MainTitle, SecondTitle, Steps, ThridTitle, Button } from "./styled";
import GoBack from "../../assets/Go-back.png";
import { useGlobalContext } from "../../context/GlobalContext";
import ModalTrivia from "../ModalTrivia/ModalTrivia";
import { getTriviaCounter } from "../../api/home";
import { useQuery } from "@tanstack/react-query";
import { CircularProgress } from "@mui/material";

const Instructions = ({ onMouseMove, screenWidth }: any) => {
  const { startTrivia, isRegistered } = useGlobalContext();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleStartTrivia = () => {
    handleOpen();
    startTrivia();
  };

  const { data: timePerQuestion = 0, isLoading } = useQuery({
    queryKey: ["getTriviaCounter"],
    queryFn: () => getTriviaCounter(),
    enabled: isRegistered,
  });

  useEffect(() => {
    if (screenWidth < 900) {
      onMouseMove(15.88);
    }
  }, []);

  return (
    <MainLayout>
      <ModalTrivia
        open={open}
        handleClose={handleClose}
        onMouseMove={onMouseMove}
        dynamicTimePerQuestion={timePerQuestion}
        screenWidth={screenWidth}
      />
      <MainTitle>¡Es hora de jugar!</MainTitle>
      <SecondTitle>
        Antes de iniciar la Trivia, ten en cuenta las siguientes instrucciones:
      </SecondTitle>
      <Steps>
        1. Deberás responder 6 preguntas que saldrán de manera aleatoria. <br />
        2. Tendrás un tiempo de 30 segundo para responder correctamente todas
        las preguntas. <br />
        3. Cada vez que selecciones una respuesta, el juego de manera automática
        avanzará a la siguiente pregunta. <br />
        4. Si te queda tiempo una vez hayas contestado todas las preguntas y no
        estás seguro de alguna respuesta, podrás volver a cada una de las
        preguntas dando clic en el botón: “<img
          src={GoBack}
          loading="lazy"
        />“ <br />
        5. Al finalizar el tiempo descubrirás si has conseguido uno de los 100
        premios disponibles.
        <br />
      </Steps>
      <ThridTitle>
        ¡No olvides que solo ganan premio, las personas que hayan contestado
        correctamente todas las preguntas!
      </ThridTitle>
      <Button onClick={() => handleStartTrivia()} disabled={isLoading}>
        {isLoading ? (
          <CircularProgress style={{ width: "20px", height: "20px" }} />
        ) : (
          "Entendido, a jugar"
        )}
      </Button>
    </MainLayout>
  );
};

export default Instructions;
