import React from 'react';
import MainLayout from '../../Layouts/MainLayout';
import {
  Title,
  MainTitle,
  ImagesGroup1,
  ImagesGroup2,
  ImagesWrapper,
  Img1,
  Img2,
  Img3,
  Img4,
  MainTitleWrapper,
  LinesImg,
  PlaneImg,
  YTitle,
  MainWrapper,
  TitleShadow,
  SecondaryTitle,
} from './styled';
import Jersey from '../../assets/jersey.png';
import Balon from '../../assets/balon.png';
import Bufanda from '../../assets/bufanda.png';
import Gymbag from '../../assets/gymbag.png';
import Lines from '../../assets/lines.png';
import Plane from '../../assets/plane-icon.png';
import { RegisterButton } from '../../ui/common';
import { useGlobalContext } from '../../context/GlobalContext';

const Prices = ({ onMouseMove, screenWidth }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();
  return (
    <MainLayout>
      <RegisterButton onClick={() => false}>
        Registrar nuevo ticket
      </RegisterButton>
      <ImagesWrapper>
        <ImagesGroup1>
          <Img1 src={Jersey} loading='eager' />
          <Img2 src={Balon} loading='eager' />
        </ImagesGroup1>
        <ImagesGroup2>
          <Img3 src={Bufanda} loading='eager' />
          <Img4 src={Gymbag} loading='eager' />
        </ImagesGroup2>
      </ImagesWrapper>
      <MainWrapper>
        <Title>¡Hay 100 premios disponibles!</Title>
        <YTitle>y</YTitle>
        <MainTitleWrapper>
          <LinesImg src={Lines} loading='eager' />
          <PlaneImg src={Plane} loading='eager' />
          <TitleShadow>Un magnífico viaje</TitleShadow>
          <MainTitle>
            a <span>España...</span>
          </MainTitle>
          <SecondaryTitle>
            ¡Conozcamos juntos <span>Madrid y Valladolid!</span>
          </SecondaryTitle>
        </MainTitleWrapper>
      </MainWrapper>
    </MainLayout>
  );
};

export default Prices;
