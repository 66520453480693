import MainLayout from '../../Layouts/MainLayout';
import { RegisterButton } from '../../ui/common';
import { MainTitle, Wrapper, Img, Step } from './styled';
import Botellas from '../../assets/BottlesM.png';
import Walmart from '../../assets/Walmart-Logo-Blanco.svg';
import { useGlobalContext } from '../../context/GlobalContext';

const Step1M = ({ onMouseMove }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();
  return (
    <MainLayout>
      <RegisterButton onClick={() => false}>
        Registrar nuevo ticket
      </RegisterButton>
      <Wrapper>
        <MainTitle>Participar es muy fácil:</MainTitle>
        <Img src={Botellas} loading='lazy' />
        <Step>
          1. Compra Sangre de Toro Original 750ml o Sangre de Toro Reserva 750ml
          en:
        </Step>
        <img className='walmartLogo' src={Walmart} loading='lazy' />
      </Wrapper>
    </MainLayout>
  );
};

export default Step1M;
