import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Img = styled.img`
  width: 337px;
  height: 198px;
  margin-top: 28px;
  margin-bottom: 87px;
`;

export const Step = styled.p`
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 24px 0 24px;
  color: #ffffff;
  span {
    font-weight: 700;
  }
`;
