import React from 'react';
import MainLayout from '../../Layouts/MainLayout';
import {
  MainTitle,
  RightWrapper,
  PriceTitle,
  EmailTitle,
  Wrapper,
  ImagesWrapper,
  ToroImg,
  WalmartImg,
} from './styled';
import Toro from '../../assets/main-logo.png';
import Walmart from '../../assets/Waltmart-Logo.png';
import { useGlobalContext } from '../../context/GlobalContext';

const Sorry = () => {
  const { questionsData, errorMessage, hasError } = useGlobalContext();
  return (
    <MainLayout>
      <Wrapper>
        <RightWrapper>
          <MainTitle>¡LO SENTIMOS!</MainTitle>
          {!errorMessage && !hasError && (
            <PriceTitle>
              Solo has respondido {questionsData.correct} de{' '}
              {questionsData.number} preguntas de manera correcta.
            </PriceTitle>
          )}
          {errorMessage && hasError && <PriceTitle>{errorMessage}</PriceTitle>}
          <EmailTitle>
            ¿Dudas? Envía un correo a: <span>contacto@metricabtl.com</span>
          </EmailTitle>
          <MainTitle>¡Sigue participando!</MainTitle>
          <ImagesWrapper>
            <ToroImg src={Toro} loading='lazy' />
            <WalmartImg src={Walmart} loading='lazy' />
          </ImagesWrapper>
        </RightWrapper>
      </Wrapper>
    </MainLayout>
  );
};

export default Sorry;
