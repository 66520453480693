import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #ba0c2f;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  @media (max-width: 900px) {
    img {
      margin-bottom: 50px;
    }
  }
`;

export const MainTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  color: #c6ad7c;
  max-width: 850px;
  margin-bottom: 108px;
  @media (max-width: 900px) {
    font-size: 25px;
    line-height: 24px;
    width: 98%;
    margin-bottom: 20px;
  }
`;

export const ButtonWhite = styled.button`
  width: 542.5px;
  height: 63px;
  background-color: #ffffff;
  color: #ba0c2f;
  border: 1px solid #ffffff;
  border-radius: 33px;
  font-size: 25px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  margin: 0 16px 0;
  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 19px;
    width: calc(100% - 32px);
    margin: 0 16px 16px;
  }
`;
export const ButtonRed = styled.a`
  width: 542.5px;
  height: 63px;
  background-color: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 33px;
  font-size: 25px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: 0.5s ease-in-out;
  margin: 0 0 16px;
  &:hover {
    background-color: #ffffff;
    color: #ba0c2f;
  }
  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 19px;
    width: calc(100% - 32px);
    margin: 0 16px 16px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 9;
`;

export const ImgBull = styled.img`
  position: absolute;
  z-index: 1;
  left: -150px;
  bottom: -45px;
  scale: 1.4;
  @media (max-width: 900px) {
    scale: 0.7;
  }
`;
