import React from 'react';
import MainLayout from '../../Layouts/MainLayout';
import {
  MainTitle,
  Step,
  Wrapper,
  Paragraph,
  ParagraphWrapper,
  WaltmartLogo,
  ImagesWrapper,
  BottleImage,
  CollarinImg,
} from './styled';
import Walmart from '../../assets/Walmart-Logo-Blanco.svg';
import Red from '../../assets/Red-large-bottle.png';
import Black from '../../assets/Black-large-bottle.png';
import Collarin from '../../assets/collarin.png';
import { RegisterButton } from '../../ui/common';
import { useGlobalContext } from '../../context/GlobalContext';

const Steps1 = ({ onMouseMove }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();
  return (
    <MainLayout>
      <RegisterButton onClick={() => false}>
        Registrar nuevo ticket
      </RegisterButton>
      <MainTitle>Participar es muy fácil:</MainTitle>
      <Wrapper>
        <Step>
          <ImagesWrapper>
            <BottleImage src={Black} loading='lazy' />
            <BottleImage src={Red} loading='lazy' />
          </ImagesWrapper>
          <ParagraphWrapper>
            <Paragraph>
              1. Compra Sangre de Toro Original 750ml o Sangre de Toro Reserva
              750ml en:
            </Paragraph>
            <WaltmartLogo src={Walmart} loading='lazy' className='walmartLogo' />
          </ParagraphWrapper>
        </Step>
        <Step>
          <CollarinImg src={Collarin} loading='lazy' />
          <ParagraphWrapper>
            <Paragraph>
              2. Escanea el código QR del collarín o entra a:
              <span>&nbsp;www.minuto90.mx</span>
            </Paragraph>
          </ParagraphWrapper>
        </Step>
      </Wrapper>
    </MainLayout>
  );
};

export default Steps1;
