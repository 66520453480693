import styled from 'styled-components';

export const MainTitle = styled.h1`
  font-size: 50px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 38px;
  }
`;

export const PointsTitle = styled.p`
  font-size: 30px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #c6ad7c;
  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 19px;
  }
`;

export const PointsText = styled.p`
  font-size: 25px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  max-width: 500px;
  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 19px;
  }
`;

export const Button = styled.button`
  width: 314px;
  height: 40px;
  border-radius: 10px;
  font-family: 'ITC Caslon 224 Std';
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  border: none;
  color: #ffffff;
  background-color: #C6AD7C;
  display: flex;
  align-items:center ;
  justify-content:center ;
  @media (max-width: 900px) {
    width: 100%;
    min-width: 270px;
  }
`;

export const EmailTitle = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 300px;
  color: #ffffff;
  span {
    font-weight: 700;
  }
  @media (max-width: 900px) {
    font-family: Gotham;
    font-size: 20px;
    line-height: 19px;
    span {
      font-weight: 700;
    }
  }
`;

export const ContinueTitle = styled.p`
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  max-width: 590px;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const Table = styled.table`
  width: 100%;
  max-width: 966px;
  @media (max-width: 900px) {
    max-width: 100%;
    padding:0 12px;
  }
`;
export const Thead = styled.thead``;
export const Tr = styled.tr`
  border-bottom: 2px solid #d43a42;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  @media (max-width: 900px) {
    th:nth-child(3),
    th:nth-child(4),
    td:nth-child(3),
    td:nth-child(4) {
      display: none;
    }
  }
`;
export const Th = styled.th`
  height: 55px;
  text-align: center;
  vertical-align: middle;
  @media (max-width: 900px) {
    max-width: 50%;
    min-width: 150px;
    width: 50%;
    min-height:55px;
    height:55px ;
  }
`;
export const Tbody = styled.tbody``;
export const Td = styled.td`
  height: 55px;
  text-align: center;
  vertical-align: middle;
  padding-top: 18px;
  color: #ffffff;
  font-size: 25px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  @media (max-width: 900px) {
    min-height:55px;
    height:55px ;
    font-size: 20px;
    line-height: 19px;
    max-width: 50%;
    min-width: 150px;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
