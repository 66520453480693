import MainLayout from '../../Layouts/MainLayout';
import {
  MainTitle,
  PointsTitle,
  PointsText,
  Button,
  EmailTitle,
  ContinueTitle,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
} from './styled';
import { useGlobalContext } from '../../context/GlobalContext';
import dayjs from 'dayjs';

const Leaderboard = ({ onMouseMove, screenWidth }: any) => {
  const { scoreData = [], setUnValidateEmail } = useGlobalContext();

  const countAprobadoTrue = scoreData.filter(
    (item) => item.aprobado === true
  ).length;

  const onHandleNewTicket = () => {
    setUnValidateEmail();
    onMouseMove(screenWidth > 900 ? 11.25 : 17.50);
  };
  return (
    <MainLayout>
      <MainTitle>
        ¡Hola, {scoreData?.[0]?.nombre ?? scoreData?.[0]?.nombre}!
      </MainTitle>
      <PointsTitle>
        Actualmente tienes {countAprobadoTrue} punto
        {scoreData?.length === 1 ? '' : 's'}
      </PointsTitle>
      <PointsText>Sigue sumando puntos, registrando más folios</PointsText>
      <Table>
        <Thead>
          <Tr>
            <Th>Fecha</Th>
            <Th>Folio de ticket</Th>
            <Th>Puntos</Th>
            <Th>Estado</Th>
          </Tr>
        </Thead>
        <Tbody>
          {scoreData?.map((data: any) => {
            return (
              <Tr key={data.folio}>
                <Td>{dayjs(data.fecha_creacion).format('DD/MM/YYYY')}</Td>
                <Td>{data.folio}</Td>
                <Td>{data.aprobado ? '1' : '0'}</Td>
                <Td>
                  {data.aprobado === true
                    ? 'Aprobado'
                    : data.aprobado === null
                    ? 'En Proceso'
                    : 'Rechazado'}
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>

      <Button onClick={() => onHandleNewTicket()}>
        Registrar un nuevo folio
      </Button>
      <EmailTitle>
        ¿Dudas? Envía un correo a: <span>contacto@metricabtl.com</span>
      </EmailTitle>
      <ContinueTitle>
        Sigue acumulando puntos para ganar un viaje a España.
      </ContinueTitle>
    </MainLayout>
  );
};

export default Leaderboard;
