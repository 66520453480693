import { useState, SyntheticEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import withAuth from '../../auth/Auth';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Questions from './Questions';
import LeaderBoard from './LeaderBoard';
import LeaderBoardPrices from './LeaderBoardPrices';
import Participants from './Participants';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const Dashboard = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleLogout = () => {
    localStorage.removeItem('sdt');
    navigate('/login');
  };
  const handleGoToLanding = () => {
    const newTab = window.open('/', '_blank');
    if (newTab) {
      newTab.focus();
    }
    setValue(1);
  };
  return (
    <Box sx={{ width: '100%' }} style={{ backgroundColor: '#ffffff' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          '.Mui-selected': {
            color: '#BA0C2F !important',
          },
          '.MuiTabs-indicator': {
            backgroundColor: '#BA0C2F !important',
          },
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <Tab label='Participantes' />
          <Tab label='Tabla de Puntos' />
          <Tab label='Tabla de Premios' />
          <Tab label='Preguntas' />
          <Tab label='Ir a Landing' onClick={handleGoToLanding} />
          <Tab label='Cerrar Sesión' onClick={handleLogout} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Participants />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <LeaderBoard />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <LeaderBoardPrices />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Questions />
      </CustomTabPanel>
    </Box>
  );
};

export default withAuth(Dashboard);
