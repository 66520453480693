export const xorEncrypt = (data: string, key: string): string => {
  let result = '';
  for (let i = 0; i < data.length; i++) {
    const charCode = data.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    result += String.fromCharCode(charCode);
  }
  return result;
};

export const xorDecrypt = (encryptedData: string, key: string): string => {
  let result = '';
  for (let i = 0; i < encryptedData.length; i++) {
    const charCode =
      encryptedData.charCodeAt(i) ^ key.charCodeAt(i % key.length);
    result += String.fromCharCode(charCode);
  }
  return result;
};

export function getErrorMessage(errorCode: number): string {
  const errorMessages: Record<number, string> = {
    417: 'Este número de folio ya ha sido registrado.',
  };

  console.log(errorCode, 'Error!');
  const errorMessage =
    errorMessages[errorCode] ||
    `Se ha producido un error inesperado. ${errorCode ? errorCode : ''}`;

  return `${errorMessage}`;
}
