import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
    padding:0 16px;
    position:relative;
  }
`;
export const RightWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;align-items: center;
  @media (max-width: 900px) {
    width: 100%;
    justify-content: flex-start;
    padding-top: 90px;
  }
`;

export const MainTitle = styled.h1`
  font-size: 60px;
  font-weight: 700;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  max-width:530px;
  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 38px;
    margin-bottom: 59px;
  }
`;

export const PriceTitle = styled.h2`
  font-family: 'ITC Caslon 224 Std';
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  max-width:560px;
  @media (max-width: 900px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 55px;
  }
`;

export const EmailTitle = styled.p`
  color: #ffffff;
  font-size: 35px;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  max-width:518px;
  span {
    font-weight: 700;
  }
  @media (max-width: 900px) {
    font-family: Gotham;
    font-size: 25px;
    line-height: 24px;
    margin-bottom: 47px;
    span {
      font-weight: 700;
    }
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  margin: 0 auto;
  max-width:400px;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const ToroImg = styled.img`
  width: 176px;
  height: 89px;
`;

export const WalmartImg = styled.img`
  width: 160px;
  height: 56px;
`;
