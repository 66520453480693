import styled from "styled-components";

export const InnerLayout = styled.div`
  display: flex;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  @media (max-width: 900px) {
    padding-top: 30px;
    justify-content: flex-start;
  }
  @media (max-width: 320px) {
    padding-top: 35px;
  }
`;

export const LeftWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 900px) {
    margin-left: 0;
    justify-content: flex-start;
  }
`;

export const WrapperWith = styled.div`
  width: 100%;
  height: 69px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 40px;
  .sangre {
    width: 307px;
    height: 59px;
    margin-right: 14px;
    @media (max-width: 900px) {
      width: 167px;
      height: 33px;
    }
  }
  .walmart {
    width: 212px;
    height: 63px;
    object-fit: contain;
    margin-left: 14px;
    scale: 1.05;
    @media (max-width: 900px) {
      width: 115px;
      height: 35px;
    }
  }
  @media (max-width: 900px) {
    margin: 0px 16px 13px;
  }
  @media (max-width: 420px) {
    scale: 0.9;
    margin-top: 15px;
  }
`;

export const TitleWith = styled.h2`
  font-size: 50px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding: 0 8px 0 4px;
  @media (max-width: 900px) {
    font-size: 25px;
    line-height: 24px;
  }
`;

export const ImgWith = styled.img``;

export const SecondTitle = styled.h2`
  font-size: 55px;
  font-weight: 400;
  line-height: 61px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  @media (max-width: 900px) {
    font-size: 45px;
    line-height: 61px;
  }
`;

export const SpainTitle = styled.h1`
  font-size: 79px;
  font-weight: 700;
  line-height: 61px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  @media (max-width: 900px) {
    font-size: 65px;
    line-height: 61px;
  }
`;

export const ImgsWrapper = styled.div`
  position: relative;
  left: 30px;
  display: flex;
  justify-content: center;
  width: 500px;
  height: 381px;
  @media (min-width: 900px) and (max-width: 1480px) {
    scale: 0.85;
    margin-bottom: 85px;
  }
  @media (max-width: 900px) {
    scale: 0.85;
  }
  @media (max-width: 375px) {
    scale: 0.7;
  }
`;

export const BottlesWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  left: calc(50% - 95px);
  transform: translateX(-50%);
  scale: 0.65;
`;

export const RedImg = styled.img`
  position: absolute;
  width: 103px;
  height: 381px;
  z-index: 3;
  left: 120px;
`;
export const BlackImg = styled.img`
  position: absolute;
  width: 103px;
  height: 381px;
  z-index: 4;
  left: 205px;
`;

export const LinesImg = styled.img`
  position: absolute;
  scale: 0.8;
`;

export const Paragraph = styled.p`
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  max-width: 1040px;
  @media (max-width: 900px) {
    font-size: 25px;
    line-height: 24px;
    padding: 40px 12px 20px;
  }
`;
