import MainLayout from '../../Layouts/MainLayout';
import { RegisterButton } from '../../ui/common';
import { Wrapper, Img, Step } from './styled';
import Computer from '../../assets/laptop.png';
import { useGlobalContext } from '../../context/GlobalContext';

const Step4M = ({ onMouseMove }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();
  return (
    <MainLayout>
      <RegisterButton onClick={() => false}>
        Registrar nuevo ticket
      </RegisterButton>
      <Wrapper>
        <Img src={Computer} loading='lazy' />
        <Step>
          4. Participa en nuestra trivia, solo tienes que contestar
          correctamente las 6 preguntas en el tiempo establecido y gana premios
          al instante.
        </Step>
      </Wrapper>
    </MainLayout>
  );
};

export default Step4M;
