import { useState } from 'react';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getParticipants,
  approveParticipant,
  rejectParticipant,
} from '../../api/dashboard';
import CircularProgress from '@mui/material/CircularProgress';
import { CenterLayout, ApproveButton, ViewButton } from './styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModalViewTicket from './ModalViewTicket';
import DownloadIcon from '@mui/icons-material/Download';
import ReadMoreIcon from '@mui/icons-material/ReadMore';
import { getImage } from '../../api/dashboard';
import ModalViewInformation from './ModalViewInformation';

const Participants = () => {
  const [open, setOpen] = useState(false);
  const [selectViewModal, setSelectViewModal] = useState<number | null>(null);
  const handleOpen = (id: number) => {
    setOpen(true);
    setSelectViewModal(id);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectViewModal(null);
  };

  const [openInformation, setOpenInformation] = useState(false);
  const [selectInfoModal, setSelectInfoModal] = useState<number | null>(null);
  const handleOpenInformation = (id: number) => {
    setOpenInformation(true);
    setSelectInfoModal(id);
  };
  const handleCloseInformation = () => {
    setOpenInformation(false);
    setSelectInfoModal(null);
  };

  const {
    data = [],
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['getParticipants'],
    queryFn: () => getParticipants(),
  });

  const { mutate, isLoading: loading } = useMutation(approveParticipant, {
    onSuccess: () => refetch(),
  });

  const { mutate: mutateReject, isLoading: rejectLoading } = useMutation(
    rejectParticipant,
    {
      onSuccess: () => refetch(),
    }
  );

  const { mutate: mutateGetImage, isLoading: getImageIsLoading } =
    useMutation<any>(getImage, {
      onSuccess: (data: any) => handleDownloadImage(data),
    });

  const handleDownloadImage = (img: any) => {
    try {
      const blob = new Blob([img], { type: 'image/jpeg' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = 'comprobante.jpg';
      a.style.display = 'none';

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };
  if (isLoading && !error)
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
  return (
    <>
      <p style={{ margin: '1em 0 2em' }}>Numero total de participantes: {data?.distinct_participantes}</p>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Participante</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Celular</TableCell>
              <TableCell>Premio</TableCell>
              <TableCell>Folio</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Aprobar/Rechazar</TableCell>
              <TableCell>Ver Ticket</TableCell>
              <TableCell>Descargar Ticket</TableCell>
              <TableCell>Ver Información</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.participantes?.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {row.nombre} {row.apellidos}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {row.email}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {row.celular}
                </TableCell>
                <TableCell component='th' scope='row'>
                  {row.premio}
                </TableCell>
                <TableCell>{row.folio}</TableCell>
                <TableCell component='th' scope='row'>
                  {row.aprobado === true
                    ? 'Aprobado'
                    : row.aprobado === null
                    ? 'En Proceso'
                    : 'Rechazado'}
                </TableCell>
                <TableCell>
                  <ApproveButton
                    disabled={loading || row.aprobado === true}
                    onClick={() => mutate(row.id)}
                  >
                    Aprobar
                  </ApproveButton>
                  <ApproveButton
                    disabled={rejectLoading || row.aprobado === false}
                    onClick={() => mutateReject(row.id)}
                  >
                    Rechazar
                  </ApproveButton>
                </TableCell>
                <TableCell component='th' scope='row'>
                  {selectViewModal === row.id && (
                    <ModalViewTicket
                      open={open}
                      handleClose={handleClose}
                      gcsfile={row.gcsname}
                      data={data}
                      id={row.id}
                    />
                  )}

                  <ViewButton onClick={() => handleOpen(row.id)}>
                    <VisibilityIcon />
                  </ViewButton>
                </TableCell>
                <TableCell>
                  <ViewButton
                    onClick={() => mutateGetImage(row.gcsname)}
                    disabled={getImageIsLoading}
                  >
                    <DownloadIcon />
                  </ViewButton>
                </TableCell>
                <TableCell>
                  {selectInfoModal === row.id && (
                    <ModalViewInformation
                      open={openInformation}
                      handleClose={handleCloseInformation}
                      data={row}
                    />
                  )}
                  <ViewButton onClick={() => handleOpenInformation(row.id)}>
                    <ReadMoreIcon />
                  </ViewButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Participants;
