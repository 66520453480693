import { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createQuestion } from '../../api/dashboard';
import {
  Form,
  FormButton,
  FormTitle,
  InputWrapper,
  Label,
  Input,
  InputCheckbox,
  InputInnerWrapper,
  Error,
} from './styled';
import CircularProgress from '@mui/material/CircularProgress';
import * as yup from 'yup';

interface BuildObject {
  id?: number;
  pregunta: string;
  respuestas: {
    id?: number;
    respuesta: string;
    correcta: boolean;
  }[];
}

const schema = yup.object().shape({
  question: yup.string().required('Pregunta es un campo requerido.'),
  answer1: yup.string().required('Pregunta 1 es un campo requerido.'),
  answer2: yup.string().required('Pregunta 2 es un campo requerido.'),
  answer3: yup.string().required('Pregunta 3 es un campo requerido.'),
  correctAnswer: yup
    .string()
    .notOneOf(['answerNaN'], 'Selecciona una respuesta como correcta.'),
  // .required('Selecciona una respuesta como correcta.'),
});

const ModalAddQuestion = ({
  handleClose,
  open,
  refetch,
  editData,
  setEditData,
}: any) => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      question: '',
      answer1: '',
      answer2: '',
      answer3: '',
      correctAnswer: '',
    },
  });

  useEffect(() => {
    if (editData) {
      const correctAnswerIndex =
        editData?.respuestas?.findIndex((answer: any) => answer.correcta) + 1 ??
        '';
      setValue('question', editData?.pregunta);
      setValue('answer1', editData?.respuestas?.[0]?.respuesta);
      setValue('answer2', editData?.respuestas?.[1]?.respuesta);
      setValue('answer3', editData?.respuestas?.[2]?.respuesta);
      setValue('correctAnswer', `answer${correctAnswerIndex}`);
    }
  }, [editData, setValue]);

  useEffect(() => {
    return () => {
      if (open) {
        setEditData({});
      }
    };
  }, [open]);

  const correctAnswer = watch('correctAnswer');
  const answer1 = watch('answer1');
  const answer2 = watch('answer2');
  const answer3 = watch('answer3');

  const handleRadioChange = (e: any) => {
    setValue('correctAnswer', e.target.value);
  };

  const { isLoading, mutate } = useMutation(createQuestion, {
    onSuccess: () => handleOnSuccess(),
  });

  const handleOnSuccess = () => {
    handleClose();
    refetch();
  };

  const onSubmit = (data: any) => {
    const respuestas: BuildObject['respuestas'] = [
      {
        respuesta: data.answer1 ?? '',
        correcta: data.correctAnswer === 'answer1',
      },
      {
        respuesta: data.answer2 ?? '',
        correcta: data.correctAnswer === 'answer2',
      },
      {
        respuesta: data.answer3 ?? '',
        correcta: data.correctAnswer === 'answer3',
      },
    ];

    if (editData) {
      respuestas.forEach((respuesta, index) => {
        if (editData.respuestas?.[index]?.id !== undefined) {
          respuesta.id = editData.respuestas[index].id;
        }
      });
    }

    const buildObject: BuildObject = {
      pregunta: data.question,
      respuestas,
    };

    if (editData) {
      buildObject.id = editData.id;
    }

    mutate([buildObject]);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormTitle>
          {editData.id ? 'Editar Pregunta' : 'Crear Pregunta'}
        </FormTitle>
        <InputWrapper>
          <Label htmlFor='question'>Pregunta</Label>
          <Input {...register('question')} type='text' id='question' />
          {errors.question && errors.question.message && (
            <Error>{errors.question.message.toString()}</Error>
          )}
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor='answer1'>Respuesta 1</Label>
          <InputInnerWrapper>
            <Input {...register('answer1')} type='text' id='answer1' />
            <InputCheckbox
              {...register('correctAnswer')}
              type='radio'
              value='answer1'
              onChange={handleRadioChange}
              checked={correctAnswer === 'answer1'}
              disabled={!answer1}
            />
          </InputInnerWrapper>
          {errors.answer1 && errors.answer1.message && (
            <Error>{errors.answer1.message.toString()}</Error>
          )}
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor='answer2'>Respuesta 2</Label>
          <InputInnerWrapper>
            <Input {...register('answer2')} type='text' id='answer2' />
            <InputCheckbox
              {...register('correctAnswer')}
              type='radio'
              value='answer2'
              onChange={handleRadioChange}
              checked={correctAnswer === 'answer2'}
              disabled={!answer2}
            />
          </InputInnerWrapper>
          {errors.answer2 && errors.answer2.message && (
            <Error>{errors.answer2.message.toString()}</Error>
          )}
        </InputWrapper>

        <InputWrapper>
          <Label htmlFor='answer3'>Respuesta 3</Label>
          <InputInnerWrapper>
            <Input {...register('answer3')} type='text' id='answer3' />
            <InputCheckbox
              {...register('correctAnswer')}
              type='radio'
              value='answer3'
              onChange={handleRadioChange}
              checked={correctAnswer === 'answer3'}
              disabled={!answer3}
            />
          </InputInnerWrapper>
          {errors.answer3 && errors.answer3.message && (
            <Error>{errors.answer3.message.toString()}</Error>
          )}
        </InputWrapper>

        {errors.correctAnswer &&
          errors.correctAnswer.message &&
          correctAnswer === 'answerNaN' && (
            <Error>{errors.correctAnswer.message.toString()}</Error>
          )}

        <FormButton disabled={true} type='submit'>
          {isLoading ? (
            <CircularProgress
              style={{ width: '20px', height: '20px', marginTop: '4px' }}
            />
          ) : (
            'Enviar'
          )}
        </FormButton>
      </Form>
    </Modal>
  );
};

export default ModalAddQuestion;
