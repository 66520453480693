import styled from 'styled-components';

export const MainTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  @media (min-width: 900px) and (max-width: 1480px) {
    margin-top: 1em;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  @media (min-width: 900px) and (max-width: 1480px) {
    height: 90%;
  }
`;

export const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 145px;
`;

export const Paragraph = styled.p`
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 480px;
  color: #ffffff;
`;

export const ReciboImg = styled.img`
  margin-bottom: 28px;
  @media (min-width: 900px) and (max-width: 1480px) {
    margin-bottom: 0px;
    scale: 0.75;
  }
`;

export const LaptopImg = styled.img`
  margin-bottom: 57px;
  @media (min-width: 900px) and (max-width: 1480px) {
    margin-bottom: 0px;
    scale: 0.75;
  }
`;
