import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Search from '../../assets/search.png';
import { useMutation } from '@tanstack/react-query';
import { getScore } from '../../api/home';
import { useGlobalContext } from '../../context/GlobalContext';
import { CircularProgress } from '@mui/material';

const Wrapper = styled.div<{ isOpen: boolean }>`
  transition: 0.3s;
  background-color: ${(props) =>
    props.isOpen ? 'rgba(168, 168, 168, 0.5)' : 'transparent'};
  position: absolute;
  height: ${(props) => (props.isOpen ? '100vh' : '75px')};
  width: 100%;
  z-index: 12;
  top: 0;
  right: 0;
  @media (max-width: 900px) {
    display: none;
  }
`;

const InputWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 43px;
  top: 32px;
  input {
    width: ${(props) => (props.isOpen ? '499' : '212')}px;
  }
`;

const Input = styled.input`
  box-shadow: 2px 2px 2px 0px #95131e66 inset;
  box-shadow: -2px -2px 3px 0px #cc162566 inset;
  background-color: #C6AD7C;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  border-radius: 33px;
  max-height: 41px;
  min-height: 41px;
  padding-left: 16px;
  transition: 0.3s;
  border: none;
  outline: none;
  &::placeholder {
    color: #ffffff;
  }
`;

const Button = styled.button`
  border: none;
  background-color: transparent;
  position: absolute;
  right: 15px;
  top: 10px;
  cursor: pointer;
`;

type FormValues = {
  email: string;
};

const schema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Email is required'),
});

const ValidateEmail = ({ onMouseMove }: any) => {
  const { setValidateEmail, onSetScoreData, onSetPageCount } =
    useGlobalContext();
  const { isLoading, mutate, data } = useMutation(getScore, {
    onSuccess: (res) => {
      onSetPageCount(2);
      setIsOnFocus(false);
      onMouseMove(screenWidth > 900 ? 13.75 : 17.50);
      setValidateEmail();
      onSetScoreData(res);
    },
  });
  const { register, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<FormValues> = (email: any) => {
    mutate(email.email);
  };

  const [isOnFocus, setIsOnFocus] = useState(false);
  const placeholder = isOnFocus
    ? 'Ingresa tu correo para ver los puntos que has acumulado'
    : 'Validar mis puntos';

    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateScreenWidth);
  
      return () => {
        window.removeEventListener('resize', updateScreenWidth);
      };
    }, []);

  return (
    <Wrapper isOpen={isOnFocus}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper isOpen={isOnFocus}>
          <Input
            disabled={isLoading}
            type='text'
            placeholder={placeholder}
            {...register('email')}
            onFocus={() => setIsOnFocus(true)}
            onBlur={() => setIsOnFocus(false)}
          />
          <Button type='submit' disabled={isLoading}>
            {isLoading ? (
              <CircularProgress style={{ width: '20px', height: '20px' }} />
            ) : (
              <img src={Search} loading='eager' />
            )}
          </Button>
        </InputWrapper>
      </form>
    </Wrapper>
  );
};

export default ValidateEmail;
