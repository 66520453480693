import MainLayout from '../../Layouts/MainLayout';
import { RegisterButton } from '../../ui/common';
import { Wrapper, Step, ImagesWrapper } from './styled';
import Bottles from '../../assets/step5Bottles.png';
import Plus from '../../assets/plus.png';
import Recibo from '../../assets/recibo.png';
import Equal from '../../assets/equal.png';
import Point from '../../assets/1Point.png';
import { useGlobalContext } from '../../context/GlobalContext';

const Step5M = ({ onMouseMove }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();
  return (
    <MainLayout>
      <RegisterButton onClick={() => false}>
        Registrar nuevo ticket
      </RegisterButton>
      <Wrapper>
        <ImagesWrapper>
          <img src={Bottles} loading='lazy' />
          <img src={Plus} loading='lazy' />
          <img src={Recibo} loading='lazy' />
          <img src={Equal} loading='lazy' />
          <img src={Point} loading='lazy' />
        </ImagesWrapper>
        <Step>
          5. Cada compra equivale a un punto, mientras más puntos tengas, más
          posibilidades tienes de ganarte el viaje a España.
        </Step>
      </Wrapper>
    </MainLayout>
  );
};

export default Step5M;
