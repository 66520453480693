import { useState, useEffect } from "react";
import {
  LeftWrapper,
  SecondTitle,
  ImgWith,
  SpainTitle,
  TitleWith,
  Paragraph,
  WrapperWith,
  LinesImg,
  ImgsWrapper,
  InnerLayout,
  RedImg,
  BlackImg,
  BottlesWrapper,
} from "./styled";
import MainLayout from "../../Layouts/MainLayout";
import SangreLogo from "../../assets/Sangre-Logo.png";
import WalmartLogoBlanco from "../../assets/Walmart-Logo-Blanco.svg";
import Lines from "../../assets/LinesWithPlane.png";
import Red from "../../assets/Red-large-bottle.png";
import Black from "../../assets/Black-large-bottle.png";
import { RegisterButton } from "../../ui/common";
import ModalStart from "../ModalStart";
import { useGlobalContext } from "../../context/GlobalContext";

const LetsGoToSpain = ({ onMouseMove, screenWidth }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    handleOpen();
  }, []);

  return (
    <MainLayout>
      <RegisterButton onClick={() => false}>
        Registrar nuevo ticket
      </RegisterButton>
      <ModalStart open={open} handleClose={handleClose} />
      <InnerLayout>
        <LeftWrapper>
          <WrapperWith>
            <ImgWith
              src={SangreLogo}
              alt="Sangre de Toro Logo"
              className="sangre"
            />
            <TitleWith>y</TitleWith>
            <ImgWith
              src={WalmartLogoBlanco}
              alt="Waltmart Express Logo"
              className="walmart"
            />
          </WrapperWith>
          <SecondTitle>Te llevan a</SecondTitle>
          <SpainTitle>¡España!</SpainTitle>
          <ImgsWrapper>
            <BottlesWrapper>
              <RedImg src={Red} />
              <BlackImg src={Black} />
            </BottlesWrapper>
            <LinesImg src={Lines} />
          </ImgsWrapper>
          <Paragraph>
          ¡Muchas felicidades a Pablo Villavicencio por ser el ganador de la campaña! <br /> Agradecemos a todos su
            participación.
          </Paragraph>
        </LeftWrapper>
      </InnerLayout>
    </MainLayout>
  );
};

export default LetsGoToSpain;
