import React from 'react';
import MainLayout from '../../Layouts/MainLayout';
import {
  MainTitle,
  Step,
  Wrapper,
  Paragraph,
  ParagraphWrapper,
  ReciboImg,
  ImagesWrapper,
} from './styled';
import { RegisterButton } from '../../ui/common';
import Bottles from '../../assets/step5Bottles.png';
import Plus from '../../assets/plus.png';
import Recibo from '../../assets/recibo.png';
import Equal from '../../assets/equal.png';
import Point from '../../assets/1Point.png';
import { useGlobalContext } from '../../context/GlobalContext';

const Steps3 = ({ onMouseMove }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();
  return (
    <MainLayout>
      <RegisterButton onClick={() => false}>
        Registrar nuevo ticket
      </RegisterButton>
      <MainTitle>Participar es muy fácil:</MainTitle>
      <Wrapper>
        <Step>
          <ImagesWrapper>
            <ReciboImg src={Bottles} loading='lazy' />
            <ReciboImg src={Plus} loading='lazy' />
            <ReciboImg src={Recibo} loading='lazy' />
            <ReciboImg src={Equal} loading='lazy' />
            <ReciboImg src={Point} loading='lazy' />
          </ImagesWrapper>
          <ParagraphWrapper>
            <Paragraph>
              5. Cada compra equivale a un punto, mientras más puntos tengas,
              más posibilidades tienes de ganarte el viaje a España.
            </Paragraph>
          </ParagraphWrapper>
        </Step>
      </Wrapper>
    </MainLayout>
  );
};

export default Steps3;
