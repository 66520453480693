import { ReactNode } from 'react';
import styled from 'styled-components';
import Bull from '../assets/full-toro.png';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
`;
const Img = styled.img`
  position: absolute;
  scale: 1.1;
  bottom: 60px;
  left: 500px;
  z-index: -1;
  @media (min-width: 900px) and (max-width: 1480px) {
    bottom: 0px;
    left: 160px;
    scale: 0.9;
  }
  @media (max-width: 900px) {
    scale: 0.42;
    left: -273px;
  }
  @media (max-width: 320px) {
    scale: 0.35;
    left: -308px;
  }
`;

interface IChildren {
  children: ReactNode;
}

const MainLayout = ({ children }: IChildren) => {
  return (
    <Wrapper>
      {children}
      <Img src={Bull} alt='' loading='eager' />
    </Wrapper>
  );
};

export default MainLayout;
