import styled from 'styled-components';

export const RegisterButton = styled.div`
  position: absolute;
  width: 264px;
  height: 39px;
  background-color: #C6AD7C;
  box-shadow: 4px 4px 5px 0px #95131e4d;
  box-shadow: -4px -4px 10px 0px #cc16254d;
  font-size: 20px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  right: 25px;
  top: 44px;
  display:flex ;
  align-items:center ;
  justify-content:center ;
  border-radius:10px ;
  cursor: pointer;
  z-index: 99;
  @media (max-width: 900px) {
    width: 192px;
    height: 18px;
    top: 25px;
    right: 17px;
    font-size: 15px;
    line-height: 14px;
  }
`;
