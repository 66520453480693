import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GlobalProvider } from './context/GlobalContext';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import GlobalStyles from './GlobalStyles';
import App from './App';
import './fonts.css';
import './styles.css'

const baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://api-sangre-toro.minuto90.mx'
    : '/';

axios.defaults.baseURL = baseURL;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 21600,
      cacheTime: 43200,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Router>
      <GlobalStyles />
      <QueryClientProvider client={queryClient}>
        <GoogleReCaptchaProvider reCaptchaKey='6Lf7JVQoAAAAABCLtFAI31Pia-4veDW3_bV3lSus'>
          <GlobalProvider>
            <App />
          </GlobalProvider>
        </GoogleReCaptchaProvider>
      </QueryClientProvider>
    </Router>
  </React.StrictMode>
);
