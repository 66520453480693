import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .walmartLogo {
    width: 229px;
  }
`;

export const MainTitle = styled.h1`
  font-size: 30px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  max-width: 300px;
  margin-top: 74px;
`;

export const Img = styled.img`
  width: 190px;
  height: 280px;
  margin-top: 28px;
  margin-bottom: 28px;
`;

export const Step = styled.p`
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 24px 0 24px;
  color: #ffffff;
`;
