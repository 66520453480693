import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getLeaderBoardPrices } from '../../api/dashboard';
import CircularProgress from '@mui/material/CircularProgress';
import { CenterLayout } from './styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const LeaderBoardPrices = () => {
  const {
    data = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['getLeaderBoardPrices'],
    queryFn: () => getLeaderBoardPrices(),
  });

  if (isLoading && !error)
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
    
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Premios</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row: any) => (
            <TableRow
              key={row.email}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component='th' scope='row'>
                {row.email}
              </TableCell>
              <TableCell component='th' scope='row'>
                {row.premios.map((premio: any) => {
                  return <p key={premio.id}>{premio.nombre}</p>;
                })}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LeaderBoardPrices;
