import { useRef, useEffect, useState } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import LetsGoToSpain from '../../components/LetsGoToSpain';
import Prices from '../../components/Prices';
import Form from '../../components/Form';
import Steps1 from '../../components/Steps1';
import Steps2 from '../../components/Steps2';
import Steps3 from '../../components/Steps3';
import Instructions from '../../components/Instructions';
import Congratulations from '../../components/Congratulations';
import Sorry from '../../components/Sorry';
import Leaderboard from '../../components/Leaderboard';
import { useGlobalContext } from '../../context/GlobalContext';
import Step1M from '../../components/Step1M';
import Step2M from '../../components/Step2M';
import Step3M from '../../components/Step3M';
import Step4M from '../../components/Step4M';
import Step5M from '../../components/Step5M';
import MainLayout from '../../Layouts/MainLayout';

const Home = () => {
  const {
    hasValidateEmail,
    hasCompletedTrivia,
    isRegistered,
    questionsData,
    setWinner,
  } = useGlobalContext();
  const parallaxRef = useRef<any>(null);

  const onMouseMove = (id: any) => {
    if (parallaxRef.current) {
      (parallaxRef.current as any).scrollTo(id);
    }
  };
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  useEffect(() => {
    if (questionsData.trivia && questionsData.premio_id) {
      setWinner();
    }
  }, [questionsData, setWinner]);

  if (!screenWidth) return null;
  return (
    <>
      {screenWidth > 900 ? (
        <Parallax pages={12.5} ref={parallaxRef} className='mainPage' enabled={false}>
          <ParallaxLayer offset={0} speed={0.25}>
            <LetsGoToSpain
              onMouseMove={onMouseMove}
              screenWidth={screenWidth}
            />
          </ParallaxLayer>
          <ParallaxLayer
            offset={1.25}
            speed={1}
            sticky={{ start: 1.25, end: 2.5 }}
          >
            <Steps1 onMouseMove={onMouseMove} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={2.5}
            speed={1}
            sticky={{ start: 3.75, end: 5 }}
          >
            <Steps2 onMouseMove={onMouseMove} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={5}
            speed={1}
            sticky={{ start: 6.25, end: 7.5 }}
          >
            <Steps3 onMouseMove={onMouseMove} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={7.5}
            speed={1}
            sticky={{ start: 8.75, end: 10 }}
          >
            <Prices onMouseMove={onMouseMove} screenWidth={screenWidth} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={10}
            speed={1}
            sticky={{ start: 11.25, end: 12.5 }}
          >
            {/* 1 */}
            {!isRegistered && !hasValidateEmail && !hasCompletedTrivia && (
              <Form onMouseMove={onMouseMove} screenWidth={screenWidth} />
            )}
            {isRegistered && !hasValidateEmail && !hasCompletedTrivia && (
              <Instructions
                onMouseMove={onMouseMove}
                screenWidth={screenWidth}
              />
            )}
            {/* 2 */}
            {hasValidateEmail && !hasCompletedTrivia && (
              <Leaderboard
                onMouseMove={onMouseMove}
                screenWidth={screenWidth}
              />
            )}
            {hasCompletedTrivia &&
              questionsData?.trivia &&
              !hasValidateEmail && <Congratulations />}
            {hasCompletedTrivia &&
              !questionsData?.trivia &&
              !hasValidateEmail && <Sorry />}
          </ParallaxLayer>
        </Parallax>
      ) : (
        <Parallax pages={19.25} ref={parallaxRef} className='mainPage' enabled={false}>
          <ParallaxLayer offset={0} speed={0.25}>
            <LetsGoToSpain
              onMouseMove={onMouseMove}
              screenWidth={screenWidth}
            />
          </ParallaxLayer>
          <ParallaxLayer
            offset={1.25}
            speed={1}
            sticky={{ start: 1.25, end: 2.5 }}
          >
            <Step1M onMouseMove={onMouseMove} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={2.5}
            speed={1}
            sticky={{ start: 3.75, end: 5 }}
          >
            <Step2M onMouseMove={onMouseMove} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={5}
            speed={1}
            sticky={{ start: 6.25, end: 7.5 }}
          >
            <Step3M onMouseMove={onMouseMove} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={7.5}
            speed={1}
            sticky={{ start: 8.75, end: 10 }}
          >
            <Step4M onMouseMove={onMouseMove} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={10}
            speed={1}
            sticky={{ start: 11.25, end: 12.5 }}
          >
            <Step5M onMouseMove={onMouseMove} />
          </ParallaxLayer>
          <ParallaxLayer
            offset={12.5}
            speed={1}
            sticky={{ start: 13.75, end: 15 }}
          >
            <Prices onMouseMove={onMouseMove} screenWidth={screenWidth} />
          </ParallaxLayer>

          <ParallaxLayer
            offset={15}
            speed={1}
            sticky={{ start: 16.25, end: 17.5 }}
          >
            {/* 1 */}
            {!isRegistered && !hasValidateEmail && !hasCompletedTrivia && (
              <Form onMouseMove={onMouseMove} screenWidth={screenWidth} />
            )}
            {isRegistered && !hasValidateEmail && !hasCompletedTrivia && (
              <Instructions
                onMouseMove={onMouseMove}
                screenWidth={screenWidth}
              />
            )}
            {/* 2 */}
            {hasValidateEmail && !hasCompletedTrivia && (
              <Leaderboard
                onMouseMove={onMouseMove}
                screenWidth={screenWidth}
              />
            )}
            {hasCompletedTrivia &&
              questionsData?.trivia &&
              !hasValidateEmail && <Congratulations />}
            {hasCompletedTrivia &&
              !questionsData?.trivia &&
              !hasValidateEmail && <Sorry />}
          </ParallaxLayer>
        </Parallax>
      )}
    </>
  );
};

export default Home;
