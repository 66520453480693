import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  getQuestions,
  deleteQuestion,
  updateCounter,
  getCounter,
  getTimer,
  updateTimer,
} from '../../api/dashboard';
import {
  AddQuestionButton,
  ButtonIcon,
  ButtonWrapper,
  CenterLayout,
  QuestionsWrapper,
  TimePerQuestionForm,
  LoaderWrapper,
  NumberOfQuestionForm,
} from './styled';
import ModalAddQuestion from './ModalAddQuestion';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

const schema = yup.object().shape({
  numberOfQuestions: yup.number().required('Campo requerido.'),
});

const schemaTime = yup.object().shape({
  timePerQuestion: yup.number().required('Campo requerido.'),
});

const Questions = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [editData, setEditData] = useState({});

  const {
    data = [],
    isLoading,
    error,
    refetch,
  } = useQuery({
    queryKey: ['getQuestions'],
    queryFn: () => getQuestions(),
  });

  const {
    data: counter = '',
    isLoading: counterIsLoading,
    refetch: refetchCounter,
  } = useQuery({
    queryKey: ['getCounter'],
    queryFn: () => getCounter(),
  });

  const {
    data: timer = '',
    isLoading: timerIsLoading,
    refetch: refetchTimer,
  } = useQuery({
    queryKey: ['getTimer'],
    queryFn: () => getTimer(),
  });

  const { mutate, isLoading: loading } = useMutation(deleteQuestion, {
    onSuccess: () => refetch(),
  });

  const { mutate: updateNumberOfQuestions, isLoading: loadingCounter } =
    useMutation(updateCounter, {
      onSuccess: () => refetchCounter(),
    });

  const { mutate: updateQuestionsTimer, isLoading: loadingTimer } = useMutation(
    updateTimer,
    {
      onSuccess: () => refetchTimer(),
    }
  );

  const { handleSubmit, register, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit: handleSubmitTime,
    register: registerTime,
    setValue: setValueTime,
  } = useForm({
    resolver: yupResolver(schemaTime),
  });

  const handleAddQuestion = () => {
    setEditData({});
    handleOpen();
  };

  const handleEditData = (data: any) => {
    setEditData(data);
    handleOpen();
  };

  const onSubmit = (data: any) => {
    updateNumberOfQuestions(data.numberOfQuestions);
  };

  const onSubmitTime = (data: any) => {
    updateQuestionsTimer(data.timePerQuestion);
  };

  useEffect(() => {
    if (counter) {
      setValue('numberOfQuestions', counter);
    }
  }, [counter, setValue]);

  useEffect(() => {
    if (timer) {
      setValueTime('timePerQuestion', timer);
    }
  }, [timer, setValueTime]);

  if (isLoading && !error)
    return (
      <CenterLayout>
        <CircularProgress />
      </CenterLayout>
    );
  return (
    <QuestionsWrapper>
      <ButtonWrapper>
        <NumberOfQuestionForm onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor='numberOfQuestions'>Número de Preguntas:</label>
            {isLoading ? (
              <LoaderWrapper>
                <CircularProgress style={{ width: '20px', height: '20px' }} />
              </LoaderWrapper>
            ) : (
              <input
                type='number'
                id='numberOfQuestions'
                {...register('numberOfQuestions')}
                defaultValue={0}
              />
            )}
          </div>
          <div>
            <button disabled={counterIsLoading || loadingCounter} type='submit'>
              Actualizar
            </button>
          </div>
        </NumberOfQuestionForm>
        <TimePerQuestionForm onSubmit={handleSubmitTime(onSubmitTime)}>
          <div>
            <label htmlFor='timePerQuestion'>Tiempo por pregunta:</label>
            {loadingTimer || timerIsLoading ? (
              <LoaderWrapper>
                <CircularProgress style={{ width: '20px', height: '20px' }} />
              </LoaderWrapper>
            ) : (
              <input
                type='number'
                id='timePerQuestion'
                {...registerTime('timePerQuestion')}
                defaultValue={0}
              />
            )}
          </div>
          <div>
            <button disabled={timerIsLoading || loadingTimer} type='submit'>
              Actualizar
            </button>
          </div>
        </TimePerQuestionForm>

        <AddQuestionButton onClick={() => handleAddQuestion()}>
          Crear pregunta
        </AddQuestionButton>
      </ButtonWrapper>
      <TableContainer component={Paper}>
        <ModalAddQuestion
          handleClose={handleClose}
          open={open}
          refetch={refetch}
          editData={editData}
          setEditData={setEditData}
        />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Pregunta</TableCell>
              <TableCell align='left'>Respuestas</TableCell>
              <TableCell>Editar</TableCell>
              <TableCell>Borrar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row: any) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component='th' scope='row'>
                  {row.pregunta}
                </TableCell>
                <TableCell component='th' scope='row'>
                  <div>
                    <ul>
                      {row.respuestas.map((res: any) => {
                        return (
                          <li key={res.id}>
                            {res.respuesta}
                            <strong>
                              &nbsp;{res.correcta ? 'Correcta' : ''}
                            </strong>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </TableCell>
                <TableCell>
                  <ButtonIcon onClick={() => handleEditData(row)}>
                    <EditIcon style={{ color: '#BA0C2F' }} />
                  </ButtonIcon>
                </TableCell>
                <TableCell>
                  <ButtonIcon disabled={loading} onClick={() => mutate(row.id)}>
                    <DeleteIcon style={{ color: '#BA0C2F' }} />
                  </ButtonIcon>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </QuestionsWrapper>
  );
};

export default Questions;
