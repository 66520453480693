export const states = [
  { id: 1, estado: 'Aguascalientes' },
  { id: 2, estado: 'Baja California Norte' },
  { id: 3, estado: 'Baja California Sur' },
  { id: 4, estado: 'Campeche' },
  { id: 5, estado: 'Coahuila' },
  { id: 6, estado: 'Colima' },
  { id: 7, estado: 'Chiapas' },
  { id: 8, estado: 'Chihuahua' },
  { id: 9, estado: 'Ciudad de México' },
  { id: 10, estado: 'Durango' },
  { id: 11, estado: 'Estado de México' },
  { id: 12, estado: 'Guanajuato' },
  { id: 13, estado: 'Guerrero' },
  { id: 14, estado: 'Hidalgo' },
  { id: 15, estado: 'Jalisco' },
  { id: 16, estado: 'Michoacán' },
  { id: 17, estado: 'Morelos' },
  { id: 18, estado: 'Nayarit' },
  { id: 19, estado: 'Nuevo León' },
  { id: 20, estado: 'Oaxaca' },
  { id: 21, estado: 'Puebla' },
  { id: 22, estado: 'Querétaro' },
  { id: 23, estado: 'Quintana Roo' },
  { id: 24, estado: 'San Luis Potosí' },
  { id: 25, estado: 'Sinaloa' },
  { id: 26, estado: 'Sonora' },
  { id: 27, estado: 'Tabasco' },
  { id: 28, estado: 'Tamaulipas' },
  { id: 29, estado: 'Tlaxcala' },
  { id: 30, estado: 'Veracruz' },
  { id: 31, estado: 'Yucatán' },
  { id: 32, estado: 'Zacatecas' },
];
