import axios from 'axios';
import { api } from '../routes/api';

interface IFormData {
  username: string;
  password: string;
}

export interface UserToken {
  access_token: string;
  token_type: string;
  usuario: {
    id: number;
    nombre: string;
    apellido_paterno: string;
    apellido_materno: string;
    email: string;
    tipo_usuario: {
      id: number;
      tipo: string;
    };
  };
}

export const getLogin = async (loginData: IFormData): Promise<UserToken> => {
  const { username, password } = loginData;

  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);

  const response = await axios.post(api.login, formData);
  const data = response.data;
  return data;
};

