import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getLogin } from '../../api/login';
import {
  Button,
  Error,
  Form,
  Img,
  Input,
  InputWrapper,
  Label,
  MainLogo,
  Wrapper,
} from './styled';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserToken } from '../../api/login';
import * as yup from 'yup';
import Logo from '../../assets/main-logo.png';
import CircularProgress from '@mui/material/CircularProgress';
import Bull from '../../assets/full-toro.png';

type FormValues = {
  username: string;
  password: string;
};

const schema = yup.object().shape({
  username: yup.string().required('Usuario es un campo requerido.'),
  password: yup.string().required('Contraseña es un campo requerido.'),
});

const Login = () => {
  const navigate = useNavigate();
  const { isLoading, mutate } = useMutation(getLogin, {
    onSuccess: (res: UserToken) => handleOnSucess(res),
  });

  const handleOnSucess = async (res: UserToken) => {
    if (res) {
      const access_token = res?.access_token as string;

      await localStorage.setItem('sdt', access_token);

      navigate('/dashboard');
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    mutate(data);
  };
  return (
    <Wrapper className='mainPage'>
      <Img src={Bull} alt="Sangre de Toro Logo" loading='lazy' />
      <Form onSubmit={handleSubmit(onSubmit)}>
        <MainLogo src={Logo} alt='Sangre de Toro Logo' />
        <InputWrapper>
          <Label htmlFor='username'>Usuario</Label>
          <Input {...register('username')} type='text' id='username' />
          <Error>{errors.username?.message}</Error>
        </InputWrapper>
        <InputWrapper>
          <Label htmlFor='password'>Contraseña</Label>
          <Input {...register('password')} type='password' id='password' />
          <Error>{errors.password?.message}</Error>
        </InputWrapper>
        <Button disabled={isLoading} type='submit'>
          {isLoading ? (
            <CircularProgress
              style={{ width: '20px', height: '20px', marginTop: '4px' }}
            />
          ) : (
            'Enviar'
          )}
        </Button>
      </Form>
    </Wrapper>
  );
};

export default Login;
