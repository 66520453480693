import styled from 'styled-components';

export const MainTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  @media (max-width: 900px) {
    font-size: 35px;
    line-height: 33px;
    margin-bottom:36px;
  }
`;

export const SecondTitle = styled.h2`
  color: #c6ad7c;
  font-size: 35px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 800px;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 29px;
    margin-bottom:36px;
  }
`;

export const Steps = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 37px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  padding: 0 118px;
  max-width: 1290px;
  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 32px;
    padding:0 16px;
    margin-bottom:36px;
  }
`;

export const ThridTitle = styled.p`
  font-size: 40px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 1000px;
  color: #c6ad7c;
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 29px;
    margin-bottom:36px;
  }
`;

export const Button = styled.button`
  width: 542.5px;
  height: 63px;
  background-color: #ffffff;
  font-size: 25px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ba0c2f;
  border-radius: 36px;
  border: none;
  cursor: pointer;
  @media (max-width: 900px) {
    width:calc(100% - 32px);
    min-height:63px;
  }
`;
