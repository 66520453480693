import axios from "axios";
import { api } from "../routes/api";

// const token = localStorage.getItem('sdtu');
const token =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFwcEBhcHBsYWIubXgiLCJ0aXBvIjoiQXBwIn0.c0h5vEY0b5RgpTRYxM8vlfQXG4YiHAMEZmOVnQ5SM3c";

const headers = {
  Authorization: `Bearer ${token}`,
};

export const getStates = async () => {
  const response = await axios(api.getStates, { headers });
  const data = response.data;
  return data;
};

export const getPrices = async () => {
  const response = await axios(api.getPrices, { headers });
  const data = response.data;
  return data;
};

export const getTriviaCounter = async () => {
  const response = await axios(api.timer, { headers });
  const data = response.data;
  return data;
};

export const getScore = async (email: string) => {
  const buildObject = { email };
  const response = await axios.post(api.getScore, buildObject, {
    headers,
  });
  const data = response.data;
  return data;
};

export const getTrivia = async () => {
  const response = await axios(api.getTrivia, {
    headers,
  });
  const data = response.data;
  return data;
};

export const register = async ({
  data,
  answers,
  captcha,
  questions,
}: any): Promise<any> => {
  const headersRegister = {
    Authorization: `Bearer ${token}`,
    "content-type": "multipart/form-data",
  };
  const participante = {
    nombre: data.name,
    apellidos: data.lastName,
    email: data.email,
    celular: data.cellphone,
    direccion: data.address,
    folio: data.ticketNumber,
    fecha_nacimiento: data.birthday,
    estado_id: data.state,
  };
  const formData = new FormData();
  
  const updatedAnswersArray: any[] = [];

  for (const question of questions) {
    const { id, pregunta } = question;

    const matchingAnswer = answers.find(
      (answer:any) => answer.pregunta_id === id
    );

    if (matchingAnswer) {
      updatedAnswersArray.push(matchingAnswer);
    } else {
      updatedAnswersArray.push({ pregunta_id: id, respuesta_id: null });
    }
  }

  const trivia_payload = {
    trivia: updatedAnswersArray,
    participante: participante,
  };

  formData.append("trivia_payload", JSON.stringify(trivia_payload));
  formData.append("file", data.image.item(0));
  formData.append("token", captcha);
  const response = await axios.post(api.getTrivia, formData, {
    headers: headersRegister,
  });
  const res = response.data;
  return res;
};
