export const api = {
  login: '/login/token',
  question: '/pregunta',
  deleteQuestion: '/pregunta/delete',
  createQuestion: '/pregunta',
  getLeaderboard: '/admin/leaderboard',
  getLeaderboardPrices: '/admin/leaderboard/premios',
  getParticipants: '/admin/participantes',
  approveParticipant: '/admin/participantes/aprobar',
  premios: '/premios',
  updateTriviaCounter: '/trivia/contador',
  getTriviaCounter: '/trivia/contador',
  getImage: '/trivia/comprobante',
  timer: '/trivia/segundos',
  //Participants
  getStates: '/estados',
  getScore: '/score',
  getPrices: '/premios',
  getTrivia: '/trivia'
};
