import styled from 'styled-components';

export const Title = styled.h2`
  color: #ffffff;
  margin: 0 auto;
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 18px;
  @media (max-width: 900px) {
    font-size: 25px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 12px;
  }
`;

export const YTitle = styled.h3`
  color: #ffffff;
  margin: 0 auto;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 37px;
  @media (max-width: 900px) {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 16px;
    line-height: 24px;
  }
`;

export const TitleShadow = styled.h1`
  font-size: 60px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  text-shadow: -2px 2px 2px rgba(0, 0, 0, 1);
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 33px;
    margin-bottom: 10px;
  }
`;
export const SecondaryTitle = styled.p`
  font-size: 35px;
  font-weight: 400;
  line-height: 54px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  span {
    font-weight: 700;
  }
  @media (max-width: 900px) {
    font-size: 20px;
    line-height: 31px;
    span {
      font-weight: 700;
    }
  }
`;

export const MainTitleWrapper = styled.div`
  position: relative;
  @media (min-width: 900px) and (max-width: 1480px) {
    scale: 0.95;
  }
  @media (max-width: 900px) {
    scale: 0.9;
  }
`;

export const MainTitle = styled.h1`
  font-size: 60px;
  font-weight: 400;
  line-height: 57px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  span {
    font-size: 65px;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: center;
  }
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 33px;
    span {
      font-size: 30px;
      line-height: 33px;
    }
  }
`;

export const LinesImg = styled.img`
  position: absolute;
  top: -42px;
  left: -68px;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const PlaneImg = styled.img`
  position: absolute;
  right: -130px;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const ImagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  @media (min-width: 900px) and (max-width: 1480px) {
    scale: 0.9;
  }
  @media (max-width: 900px) {
    width: auto;
  }
`;

export const ImagesGroup1 = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin-top: 36px;
  margin-left: -30px;
  @media (min-width: 900px) and (max-width: 1480px) {
    margin-top: 0px;
    margin-left: -70px;
  }
  @media (max-width: 900px) {
    scale: 0.5;
    position: absolute;
    right: -95px;
    top: -160px;
  }
`;

export const ImagesGroup2 = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding-bottom: 56px;
  margin-right: -30px;
  @media (min-width: 900px) and (max-width: 1480px) {
    padding-bottom: 0px;
    margin-right: -80px;
  }
  @media (max-width: 900px) {
    margin-right: 0px;
    scale: 0.5;
    position: absolute;
    bottom: -100px;
    left: -95px;
  }
`;

export const Img1 = styled.img`
  position: relative;
  left: 38px;
  top: 6px;
  z-index: 0;
`;
export const Img2 = styled.img`
  position: relative;
  z-index: 4;
`;
export const Img3 = styled.img`
  position: relative;
  z-index: 4;
`;
export const Img4 = styled.img`
  position: relative;
  z-index: 0;
  top: 31px;
  left: -30px;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: -10px;
`;
