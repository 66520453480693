import styled from 'styled-components';

export const FormWrapper = styled.form`
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 23px;
  color: #ffffff;
  padding: 20px 0;
  width: 416px;
  z-index: 9;
  position: relative;
  @media (max-width: 1480px) {
  scale:0.92 ;
  }
  @media (max-width: 900px) {
    width: calc(100% - 64px);
  }
  .picker > div {
    padding: 0 5px;
    width: calc(100% - 98px);
    justify-content: center;
    display: flex;
    margin: 0 auto !important;
    background-color: #ba0c2f !important;
    margin-bottom: 16px !important;
    max-height: 35px;
    border-radius: 23px;
    opacity: 1 !important;
  }

  @media (max-width: 900px) {
    .picker > div {
      width: calc(100% - 50px);
    }
  }

  .picker input,
  .picker input::placeholder {
    color: #ffffff !important;
    opacity: 1 !important;
    font-size: 15px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0em;
    padding-left: 3px;
  }

  @media (max-width: 900px) {
    .picker div div input,
    .picker div div input::placeholder {
      padding-left: 3px;
    }
  }

  .picker div div button svg {
    fill: #ffffff;
  }

  .MuiInputBase-root
    .MuiOutlinedInput-root
    .MuiInputBase-colorPrimary
    .MuiInputBase-formControl
    .MuiInputBase-adornedEnd
    .MuiInputBase-root-MuiOutlinedInput-root {
    box-shadow: 2px 2px 2px 0px #95131e66 inset !important;
    box-shadow: -2px -2px 3px 0px #cc162566 inset !important;
    background-color: #ba0c2f !important;
  }

  .picker div div {
    padding-right: 0 !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
`;

export const FormTitle = styled.h2`
  font-size: 35px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-bottom: 25px;
  @media (max-width: 900px) {
    font-size: 25px;
    font-weight: 700;
    line-height: 24px;
  }
`;

export const OneLineWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div:nth-child(1) input {
    width: calc(100% - 88px);
    margin: 0px 0px 9px 45px;
    @media (max-width: 900px) {
      margin: 0px 0px 9px 16px;
      width: calc(100% - 0px);
    }
  }
  div:nth-child(2) input {
    width: calc(100% - 88px);
    margin: 0px 45px 9px 16px;
    @media (max-width: 900px) {
      margin: 0px 0px 9px 8px;
      width: calc(100% - 0px);
    }
  }
`;
export const Input = styled.input`
  width: calc(100% - 116px);
  max-height: 35px;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #ba0c2f;
  border: none;
  border-radius: 23px;
  margin-bottom: 9px;
  color: #ffffff;
  padding: 8px 13px 9px;
  max-width: 420px;
  margin: 0px 45px 9px;
  outline: #ffffff;
  box-shadow: 2px 2px 2px 0px #95131e66 inset;
  box-shadow: -2px -2px 3px 0px #cc162566 inset;

  &::placeholder {
    color: #ffffff;
  }
  @media (max-width: 900px) {
    width: calc(100% - 64px);
    max-width: calc(100% - 64px);
    margin: 0px 16px 9px;
  }
`;
export const Select = styled.select`
  width: calc(100% - 92px);
  max-height: 35px;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  background-color: #ba0c2f;
  border: none;
  border-radius: 23px;
  margin-bottom: 9px;
  color: #ffffff;
  padding: 8px 15px 9px 13px;
  max-width: 420px;
  margin: 0px 45px 9px;
  outline: #ffffff;
  box-shadow: 2px 2px 2px 0px #95131e66 inset;
  box-shadow: -2px -2px 3px 0px #cc162566 inset;
  ::placeholder {
    color: #ffffff;
  }
  @media (max-width: 900px) {
    width: calc(100% - 38px);
    max-width: calc(100% - 38px);
    margin: 0px 16px 9px;
  }
`;
export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
export const DragNDropArea = styled.div`
  border: 2px dashed #ffffff;
  height: 146px;
  width: calc(100% - 90px);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 5px 45px 19px;
  cursor: grab;
`;
export const Label = styled.label`
  font-size: 15px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  font-size: 15px;
  font-weight: 300;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 227px;
  position: relative;
  margin-top: 60px;
  cursor: pointer;
  &::before {
    content: 'Carga';
    font-weight: 500;
    text-decoration: underline;
  }
`;
export const ImgLabel = styled.img`
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
`;

export const TermsWrapper = styled.div`
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  input {
    margin-right: 10px;
    margin-top: -7px;
    cursor: pointer;
  }
  @media (max-width: 900px) {
    width: max-content;
  }
`;

export const TermsLabel = styled.label`
  font-size: 15px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  height: 20px;
  margin-bottom: 3px;
`;

export const TermsDownload = styled.a`
  font-size: 15px;
  font-weight: 700;
  font-style: italic;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  padding-left: 5px;
  position: relative;
  z-index: 4;
  @media (max-width: 900px) {
    text-align: start;
    display: flex;
    width: fit-content;
  }
`;

export const SubmitButton = styled.button`
  width: 208px;
  height: 40px;
  border-radius: 10px;
  background: #c6ad7c;
  box-shadow: 4px 4px 5px 0px #95131e4d;
  box-shadow: -4px -4px 10px 0px #cc16254d;
  font-family: 'ITC Caslon 224 Std';
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
`;

export const ErrorsWrapper = styled.div`
  max-height: 14px;
  overflow: hidden;
  margin: 10px 0;
`;
export const FormErrors = styled.p`
  font-size: 15px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
`;
