import Jersey from '../assets/premio-jersey.png'
import Balon from '../assets/premio-balon.png';
import Gymbag from '../assets/premio-gymbag.png';
import Bufanda from '../assets/premio-bufanda.png';

export const prices = [
  {
    id: 1,
    premio: 'jersey',
    title: 'Ganaste un jersey de La Roja',
    img: Jersey,
  },
  {
    id: 2,
    premio: 'balon',
    title: 'Ganaste un balón de La Roja',
    img: Balon,
  },
  {
    id: 3,
    premio: 'gymbag',
    title: 'Ganaste una gymbag de La Roja',
    img: Gymbag,
  },
  {
    id: 4,
    premio: 'bufanda',
    title: 'Ganaste una bufanda de La Roja',
    img: Bufanda,
  },
];
