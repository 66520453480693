import MainLayout from '../../Layouts/MainLayout';
import { RegisterButton } from '../../ui/common';
import { Wrapper, Img, Step } from './styled';
import Ticket from '../../assets/recibo.png';
import { useGlobalContext } from '../../context/GlobalContext';

const Step3M = ({ onMouseMove }: any) => {
  const { setUnRegistered, setUnCompletedTrivia } = useGlobalContext();
  return (
    <MainLayout>
      <RegisterButton
        onClick={() => {
          onMouseMove(16.25);
          setUnRegistered();
          setUnCompletedTrivia();
        }}
      >
        Registrar nuevo ticket
      </RegisterButton>
      <Wrapper>
        <Img src={Ticket} loading='lazy' />
        <Step>3. Registra tu ticket de compra.</Step>
      </Wrapper>
    </MainLayout>
  );
};

export default Step3M;
