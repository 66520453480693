import styled from 'styled-components';

export const MainTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: 1em;
  .walmartLogo {
    width: 229px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  @media (min-width: 900px) and (max-width: 1480px) {
    transform: scale(0.9);
  }
`;

export const ImagesWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

export const BottleImage = styled.img`
  height: 397px;
  width: 108px;
  @media (min-width: 900px) and (max-width: 1480px) {
    scale: 0.9;
  }
`;

export const ParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 200px;
`;

export const Paragraph = styled.p`
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  max-width: 410px;
  color: #ffffff;
  span {
    font-weight: 700;
  }
`;

export const WaltmartLogo = styled.img`
  width: 229px;
  height: 80px;
  margin-top: 15px;
`;

export const CollarinImg = styled.img`
  margin-bottom: 20px;
  padding-bottom: -5px;
  @media (min-width: 900px) and (max-width: 1480px) {
    scale: 0.9;
  }
`;
