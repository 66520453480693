import Modal from "@mui/material/Modal";
import { InformationWrapper, ItemWrapper } from "./styled";
import dayjs from "dayjs";

const ModalViewInformation = ({ handleClose, open, data }: any) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <InformationWrapper>
        <ItemWrapper>
          <span>Nombre:</span>
          {data.nombre}
          {data.apellidos}
        </ItemWrapper>
        <ItemWrapper>
          <span>Email:</span>
          {data.email}
        </ItemWrapper>
        <ItemWrapper>
          <span>Fecha de Nacimiento:</span>
          {dayjs(data.fecha_nacimiento).format("DD/MM/YYYY")}
        </ItemWrapper>
        <ItemWrapper>
          <span>Dirección:</span>
          {data.direccion}
        </ItemWrapper>
        <ItemWrapper>
          <span>Celular:</span>
          {data.celular}
        </ItemWrapper>
        <ItemWrapper>
          <span>Folio:</span>
          {data.folio}
        </ItemWrapper>
        <ItemWrapper>
          <span>Estado:</span>
          {data.aprobado ? "Aprobado" : "Rechazado"}
        </ItemWrapper>
        <ItemWrapper>
          <span>Fecha de Aprobación:</span>
          {dayjs(data.fecha_aprueba).format("DD/MM/YYYY")}
        </ItemWrapper>
        <ItemWrapper>
          <span>Fecha de Creación:</span>
          {dayjs(data.fecha_creacion).format("DD/MM/YYYY")}
        </ItemWrapper>
        <ItemWrapper>
          <span>Premio:</span>
          {data.premio ? data.premio : "Sin Premio"}
        </ItemWrapper>
        <ItemWrapper>
          <span>Estado Trivia:</span>
          {data.trivia_correcta ? "Correcta" : "Incorrecta"}
        </ItemWrapper>
        <ItemWrapper>
          <span>Trivia:</span> <br />
          <br />
          {data.trivia.map((trivia: any, i: number) => {
            return (
              <div key={i}>
                <span>{i + 1}. PREGUNTA:</span>
                {trivia.trivia.pregunta} <br />
                <span>RESPUESTAS:</span>
                {trivia.trivia.respuestas.map((res: any, i: number) => {
                  return (
                    <ul key={i}>
                      <li>
                        <span>{i + 1}.</span>
                        <span>Correcta:</span>
                        {res.correcta.toString()} <br />
                        <span>Respuesta:</span>
                        {res.respuesta}
                      </li>
                    </ul>
                  );
                })}
                <br />
                <span>Respuesta Seleccionada:</span>
                {trivia.selected_answer.respuesta} <br />
                <br />
              </div>
            );
          })}
        </ItemWrapper>
      </InformationWrapper>
    </Modal>
  );
};

export default ModalViewInformation;
