import React, { createContext, useContext, useState } from 'react';

type TScoreData = {
  apellidos: string;
  aprobado: boolean;
  email: string;
  fecha_creacion: string;
  folio: string;
  nombre: string;
};

type TQuestions = {
  number: number;
  correct: number;
  trivia: boolean;
  premio_id: number | null;
};
interface GlobalContextState {
  isTriviaStarted: boolean;
  isWinner: boolean;
  hasValidateEmail: boolean;
  hasCompletedTrivia: boolean;
  scoreData: TScoreData[];
  isRegistered: boolean;
  pageCount: number;
  formData: any;
  questionsData: TQuestions;
  hasError: boolean;
  errorMessage: string;
  startTrivia: () => void;
  stopTrivia: () => void;
  setWinner: () => void;
  setLooser: () => void;
  setValidateEmail: () => void;
  setUnValidateEmail: () => void;
  setCompletedTrivia: () => void;
  setUnCompletedTrivia: () => void;
  onSetScoreData: (data: TScoreData[]) => void;
  setRegistered: () => void;
  setUnRegistered: () => void;
  onSetPageCount: (number: number) => void;
  onSetFormData: (data: any) => void;
  onSetQuestionsData: (data: TQuestions) => void;
  onSetError: () => void;
  onUnSetError: () => void;
  onSetErrorMessage: (message: string) => void;
}

const GlobalContext = createContext<GlobalContextState | undefined>(undefined);

export const useGlobalContext = () => {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useGlobalContext must be used within a GlobalProvider');
  }
  return context;
};

const initalStateQuestions = {
  number: 0,
  correct: 0,
  trivia: false,
  premio_id: null,
};

export const GlobalProvider = ({ children }: any) => {
  const [isTriviaStarted, setIsTriviaStarted] = useState(false);
  const [isWinner, setIsWinner] = useState(false);
  const [hasValidateEmail, setHasValidateEmail] = useState(false);
  const [hasCompletedTrivia, setHasCompletedTrivia] = useState(false);
  const [scoreData, setScoreData] = useState<TScoreData[] | []>([]);
  const [isRegistered, setIsRegistered] = useState(false);
  const [pageCount, setPageCount] = useState(
    window?.innerHeight < 900 ? 20 : 15
  );
  const [formData, setFormData] = useState({});
  const [questionsData, SetQuestionsData] =
    useState<TQuestions>(initalStateQuestions);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const startTrivia = () => {
    setIsTriviaStarted(true);
  };
  const stopTrivia = () => {
    setIsTriviaStarted(false);
  };
  const setWinner = () => {
    setIsWinner(true);
  };
  const setLooser = () => {
    setIsWinner(false);
  };
  const setValidateEmail = () => {
    setHasValidateEmail(true);
  };
  const setUnValidateEmail = () => {
    setHasValidateEmail(false);
  };
  const setCompletedTrivia = () => {
    setHasCompletedTrivia(true);
  };
  const setUnCompletedTrivia = () => {
    setHasCompletedTrivia(false);
  };
  const onSetScoreData = (data: TScoreData[]) => {
    setScoreData(data);
  };
  const setRegistered = () => {
    setIsRegistered(true);
  };
  const setUnRegistered = () => {
    setIsRegistered(false);
  };
  const onSetPageCount = (number: number) => {
    setPageCount(number);
  };
  const onSetFormData = (data: any) => {
    setFormData(data);
  };
  const onSetQuestionsData = (data: TQuestions) => {
    SetQuestionsData(data);
  };
  const onSetError = () => {
    setHasError(true);
  };
  const onUnSetError = () => {
    setHasError(false);
  };
  const onSetErrorMessage = (message: string) => {
    setErrorMessage(message);
  };
  const contextValue: GlobalContextState = {
    isTriviaStarted,
    isWinner,
    hasValidateEmail,
    hasCompletedTrivia,
    scoreData,
    isRegistered,
    pageCount,
    formData,
    questionsData,
    hasError,
    errorMessage,
    startTrivia,
    stopTrivia,
    setWinner,
    setLooser,
    setValidateEmail,
    setUnValidateEmail,
    setCompletedTrivia,
    setUnCompletedTrivia,
    onSetScoreData,
    setRegistered,
    setUnRegistered,
    onSetPageCount,
    onSetFormData,
    onSetQuestionsData,
    onSetError,
    onUnSetError,
    onSetErrorMessage,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};
