import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Modal from '@mui/material/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { ViewTicketWrapper, ImgTicket } from './styled';
import { getImage } from '../../api/dashboard';

const ModalViewTicket = ({ handleClose, open, gcsfile, data, id }: any) => {
  const [imageUrl, setImageUrl] = useState<any>('');
  const [hasError, setHasError] = useState(false);

  const handleGCSFile = (imgData: Uint8Array) => {
    try {
      const blob = new Blob([imgData], { type: 'image/jpeg' });

      const url = URL.createObjectURL(blob);

      setImageUrl(url);
    } catch (error) {
      console.error('Error handling file:', error);
      setHasError(true);
    }
  };

  const { mutate, isLoading, error } = useMutation<any>(getImage, {
    onSuccess: (data: any) => handleGCSFile(data),
  });

  useEffect(() => {
    if (open) {
      mutate(gcsfile);
    }
  }, [open, gcsfile]);

  return (
    <Modal open={open} onClose={handleClose}>
      <ViewTicketWrapper>
        {isLoading && !error && <CircularProgress />}
        {!isLoading && !error && (
          <>{imageUrl && <ImgTicket src={imageUrl} alt='Comprobante' />}</>
        )}
        {hasError && !isLoading && !error && <p>Error.</p>}
      </ViewTicketWrapper>
    </Modal>
  );
};

export default ModalViewTicket;
