import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const withAuth = (Component: FC) => {
  const RequireAuthHoc: FC = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
      const checkLocalStorage = () => {
        const getStorage = localStorage.getItem('sdt');

        if (getStorage !== null && getStorage !== '') {
        } else {
          navigate('/login');
        }

        if (getStorage) {
          navigate('/dashboard');
        }
      };

      window.addEventListener('storage', checkLocalStorage);

      checkLocalStorage();

      return () => {
        window.removeEventListener('storage', checkLocalStorage);
      };
    }, [navigate]);

    return <Component {...props} />;
  };
  return RequireAuthHoc;
};

export default withAuth;
