import { useState, useEffect, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import {
  Layout,
  Wrapper,
  MainTitle,
  QuestionWrapper,
  Question,
  SecondsTitle,
  SecondsWrapper,
  ProgressBar,
  ProgressFiller,
  QuestionBanner,
  Button,
  AnswersWrapper,
  GoBack,
  CenterLayout,
} from './styled';
import { useGlobalContext } from '../../context/GlobalContext';
import Volver from '../../assets/Volver.png';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getTrivia } from '../../api/home';
import CircularProgress from '@mui/material/CircularProgress';
import { register } from '../../api/home';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { getErrorMessage } from '../../utils/utils';

const ModalTrivia = ({
  open,
  handleClose,
  onMouseMove,
  dynamicTimePerQuestion,
  screenWidth,
}: any) => {
  const {
    data: questions = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ['getTrivia'],
    queryFn: () => getTrivia(),
    enabled: open,
    refetchOnWindowFocus: false,
  });
  const [token, setToken] = useState('');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('yourAction');
    setToken(token);
  }, [executeRecaptcha]);

  const {
    isTriviaStarted,
    stopTrivia,
    formData,
    setCompletedTrivia,
    onSetQuestionsData,
    onSetError,
    setUnRegistered,
    onSetErrorMessage,
  } = useGlobalContext();

  const { mutate, isLoading: mutateisLoading } = useMutation(register, {
    onSuccess: (data) => {
      onSetQuestionsData({
        number: questions.length,
        correct: data.validas,
        trivia: data.trivia,
        premio_id: data.premio_id,
      });
    },
    onError: (data: any) => {
      if (data) {
        const message = getErrorMessage(data.response.status);

        onSetErrorMessage(message);
      }
      onSetError();
    },
  });

  const timePerQuestion = dynamicTimePerQuestion ?? 30;
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-2);
  const [seconds, setSeconds] = useState(timePerQuestion);
  const currentQuestion = questions[currentQuestionIndex];
  const [saveAnswers, setSaveAnswers] = useState<any>([]);
  const [isGoBack, setIsGoBack] = useState(false);
  //
  const handleAnswer = (answerId: number) => {
    //
    let preguntaId: undefined;

    for (const question of questions) {
      for (const respuesta of question.respuestas) {
        if (respuesta.id === answerId) {
          preguntaId = question.id;
          break; //
        }
      }
      if (preguntaId) {
        break;
      }
    }

    if (preguntaId !== undefined) {
      const answersUpdate = [...saveAnswers];

      const indexToUpdate = answersUpdate.findIndex(
        (item) => item.pregunta_id === preguntaId
      );

      if (indexToUpdate !== -1) {
        answersUpdate[indexToUpdate].respuesta_id = answerId;
      } else {
        answersUpdate.push({ pregunta_id: preguntaId, respuesta_id: answerId });
      }

      setSaveAnswers(answersUpdate);
    }

    //
    const buildObject = {
      pregunta_id: currentQuestion?.id,
      respuesta_id: answerId,
    };

    setSaveAnswers([...saveAnswers, buildObject]);
    if (currentQuestionIndex + 1 === questions.length) {
      stopTrivia();
      handleReCaptchaVerify();
    } else {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSeconds(timePerQuestion);
    }
  };
  //
  const handleCompareQuestions = () => {
    const updatedSaveAnswers = questions.map((question: any) => {
      const matchingAnswer = saveAnswers.find(
        (answer: any) => answer.pregunta_id === question.id
      );
      return matchingAnswer || { pregunta_id: question.id, respuesta_id: null };
    });
    setSaveAnswers(updatedSaveAnswers);
  };
  //
  const findId = saveAnswers?.find(
    (pregunta: any) => pregunta?.pregunta_id === currentQuestion?.id
  );
  //
  useEffect(() => {
    if (isLoading && open) {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  }, [open, isLoading]);
  //
  useEffect(() => {
    let intervalId: NodeJS.Timeout | undefined;

    if (!open) return;

    if (isTriviaStarted && seconds > 0 && !isGoBack && open && !isLoading) {
      intervalId = setInterval(() => {
        setSeconds((prevSeconds: number) => prevSeconds - 1);
      }, 1000);
    }

    if (seconds === 0) {
      clearInterval(intervalId);
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setSeconds(timePerQuestion);
    }

    if (isGoBack) {
      clearInterval(intervalId);
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setIsGoBack(false);
    }

    if (
      currentQuestionIndex + 1 === questions.length &&
      seconds === 0 &&
      open
    ) {
      stopTrivia();
      handleReCaptchaVerify();
    }

    if (!isTriviaStarted && open && token) {
      clearInterval(intervalId);
      handleCompareQuestions();
      mutate({ data: formData, answers: saveAnswers, captcha: token, questions });
      setCompletedTrivia();
      handleClose();
      setSeconds(0);
      onMouseMove(screenWidth > 900 ? 13.75 : 17.5);
      setCurrentQuestionIndex(-2);
      setUnRegistered();
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [
    isTriviaStarted,
    seconds,
    currentQuestionIndex,
    saveAnswers,
    isGoBack,
    findId,
    token,
    isLoading,
    open,
    setCurrentQuestionIndex,
  ]);

  const progressWidth = (seconds / timePerQuestion) * 100;
  return (
    <Modal
      style={{ backgroundColor: '#393939' }}
      open={open}
      onClose={() => false}
    >
      <>
        <MainTitle>¡Triviatime!</MainTitle>
        <Layout>
          {!isLoading && !error && !mutateisLoading && (
            <Wrapper>
              {currentQuestionIndex > 0 && (
                <GoBack onClick={() => setIsGoBack(true)}>
                  <img src={Volver} alt='eager' />
                </GoBack>
              )}
              <QuestionWrapper>
                <SecondsWrapper>
                  {seconds > 0 && <SecondsTitle>{seconds} seg</SecondsTitle>}
                  <ProgressBar>
                    <ProgressFiller width={progressWidth} />
                  </ProgressBar>
                </SecondsWrapper>
                <Question>{currentQuestion?.pregunta}</Question>

                <AnswersWrapper>
                  {currentQuestion?.respuestas?.map(
                    (res: any, index: number) => {
                      const letter = String.fromCharCode(65 + index);
                      return (
                        <Button
                          key={res?.id}
                          onClick={() => handleAnswer(res?.id)}
                        >
                          {letter}.&nbsp;{res?.respuesta}
                        </Button>
                      );
                    }
                  )}
                </AnswersWrapper>

                <QuestionBanner>
                  Pregunta: {currentQuestionIndex + 1} de {questions?.length}
                </QuestionBanner>
              </QuestionWrapper>
            </Wrapper>
          )}
          {isLoading && !error && mutateisLoading && (
            <CenterLayout>
              <CircularProgress />
            </CenterLayout>
          )}
        </Layout>
      </>
    </Modal>
  );
};

export default ModalTrivia;
