import React from 'react';
import MainLayout from '../../Layouts/MainLayout';
import {
  MainTitle,
  ImageWrapper,
  RightWrapper,
  PriceTitle,
  AddressTitle,
  EmailTitle,
  Wrapper,
  ImagesWrapper,
  ToroImg,
  WalmartImg,
} from './styled';
import Toro from '../../assets/main-logo.png';
import Walmart from '../../assets/Waltmart-Logo.png';
import { prices } from '../../constants/prices';
import { useGlobalContext } from '../../context/GlobalContext';

const Congratulations = () => {
  const { questionsData } = useGlobalContext();
  return (
    <MainLayout>
      <Wrapper>
        <ImageWrapper>
          <img
            src={
              questionsData?.premio_id != null &&
              typeof prices[questionsData.premio_id]?.img === 'string'
                ? prices[questionsData.premio_id].img
                : undefined
            }
            loading='lazy'
          />
        </ImageWrapper>
        <RightWrapper>
          <MainTitle>¡FELICIDADES!</MainTitle>
          <PriceTitle>
            {questionsData.premio_id !== null &&
              prices[questionsData.premio_id]?.title}
          </PriceTitle>
          <AddressTitle>
            Espera tu premio en la dirección que ingresaste.
          </AddressTitle>
          <EmailTitle>
            ¿Dudas? Envía un correo a: <span>contacto@metricabtl.com</span>
          </EmailTitle>
          <ImagesWrapper>
            <ToroImg src={Toro} loading='lazy' />
            <WalmartImg src={Walmart} loading='lazy' />
          </ImagesWrapper>
        </RightWrapper>
      </Wrapper>
    </MainLayout>
  );
};

export default Congratulations;
