import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;
export const Img = styled.img`
  position: absolute;
  left: -25%;
  bottom: -10%;
`;

export const Form = styled.form`
  max-width: 340px;
  width: 100%;
  max-height: 430px;
  height: 100%;
  padding: 1em 2em;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 23px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: relative;
  z-index: 99;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Input = styled.input`
  max-height: 40px;
  padding: 8px 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 8px;
  border: none;
  margin: 10px 0;
`;

export const Label = styled.label`
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
  text-align: left;
`;

export const Error = styled.p`
  height: 25px;
  font-size: 15px;
  font-weight: 800;
  line-height: 14px;
  color: #ffffff;
`;

export const MainLogo = styled.img`
  scale: 0.65;
`;

export const Button = styled.button`
  width: 208px;
  height: 39px;
  border-radius: 10px;
  background: #c6ad7c;
  font-size: 20px;
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  opacity:0.9;
`;
