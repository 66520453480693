import { useState } from 'react';
import MainLayout from '../../Layouts/MainLayout';
import {
  DragNDropArea,
  ImgLabel,
  Input,
  FormWrapper,
  Label,
  OneLineWrapper,
  SubmitButton,
  FormTitle,
  TermsWrapper,
  WrapperButton,
  Select,
  TermsLabel,
  FormErrors,
  ErrorsWrapper,
  TermsDownload,
} from './styled';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DragIcon from '../../assets/drag-icon.png';
import ValidateEmail from './ValidateEmail';
import { states } from '../../constants/states';
import { useGlobalContext } from '../../context/GlobalContext';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

const schema = yup.object().shape({
  name: yup.string().required('El nombre es un campo requerido.'),
  lastName: yup.string().required('El apellido es un campo requerido.'),
  birthday: yup
    .string()
    .required('Fecha de nacimiento es un campo requerido.')
    .matches(
      /^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/,
      'Formato invalido. Utiliza DD/MM/AAAA'
    ),
  email: yup
    .string()
    .required('El email es un campo requerido.')
    .email('Email invalido'),
  confirmEmail: yup
    .string()
    .required('Confirmar Email es un campo requerido.')
    .email('Email invalido.')
    .test('emails-match', 'Los Emails deben de conincidir.', function (value) {
      return value === this.parent.email;
    }),
  cellphone: yup
    .string()
    .required('El número de celular es un campo requerido.')
    .matches(/^\d{10}$/, 'El número de celular de tener 10 números.'),
  address: yup
    .string()
    .required('El código postal es un campo requerido.')
    .matches(/^\d{5}$/, 'El código postal debe tener exactamente 5 números.'),
  state: yup.string().required('El estado es un campo requerido.'),
  ticketNumber: yup
    .string()
    .required('El folio de ticket es un campo requerido.')
    .matches(/^\d{21}$/, 'El folio de ticket debe contener 21 números.'),
  image: yup.mixed().required('Es necesario cargar la foto de tu ticket'),
  termsAndConditions: yup
    .boolean()
    .oneOf([true], 'Debes aceptar los términos y condiciones.'),
});

const Form = ({ onMouseMove, screenWdith }: any) => {
  const { setRegistered, onSetFormData, setUnValidateEmail } =
    useGlobalContext();
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);

      setValue('image', e.target.files as any);
    } else {
      setValue('image', '');
      setImagePreview(null);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();

    const file = e.dataTransfer.files && e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result as string);
      };
      reader.readAsDataURL(file);

      setValue('image', e.dataTransfer.files);
    }
  };

  const onSubmit = (data: any) => {
    onSetFormData(data);
    setRegistered();
    setUnValidateEmail();
  };

  return (
    <MainLayout>
      <ValidateEmail onMouseMove={onMouseMove} screenWidth={screenWdith} />
      <FormWrapper onSubmit={handleSubmit(onSubmit)} className='formRegister'>
        <FormTitle>Registra tu ticket</FormTitle>
        <OneLineWrapper>
          <div>
            <Input
              type='text'
              id='name'
              placeholder='Nombre'
              {...register('name')}
            />
            {/* <p>{errors.name?.message}</p> */}
          </div>

          <div>
            <Input
              type='text'
              id='lastName'
              placeholder='Apellido:'
              {...register('lastName')}
            />
            {/* <p>{errors.lastName?.message}</p> */}
          </div>
        </OneLineWrapper>
        <div className='picker'>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='es'>
            <DatePicker
              slotProps={{
                textField: {
                  InputLabelProps: { shrink: false },
                  InputProps: { placeholder: 'Fecha de Nacimiento' },
                },
              }}
              format='DD/MM/YYYY'
              views={['year', 'month', 'day']}
              onChange={(date: any) => {
                if (date) {
                  setValue('birthday', dayjs(date)?.format('DD/MM/YYYY'));
                }
              }}
            />

            {/* <p>{errors.birthday?.message}</p> */}
          </LocalizationProvider>
        </div>
        <div>
          <Input
            type='text'
            id='email'
            placeholder='Email'
            {...register('email')}
          />
          {/* <p>{errors.email?.message}</p> */}
        </div>
        <div>
          <Input
            type='text'
            id='confirmEmail'
            placeholder='Confirma tu email:'
            {...register('confirmEmail')}
          />
          {/* <p>{errors.confirmEmail?.message}</p> */}
        </div>
        <div>
          <Input
            type='text'
            id='cellphone'
            placeholder='Número de celular:'
            {...register('cellphone')}
          />
          {/* <p>{errors.cellphone?.message}</p> */}
        </div>

        <div>
          <Input
            type='text'
            id='address'
            placeholder='Código Postal'
            {...register('address')}
          />
          {/* <p>{errors.address?.message}</p> */}
        </div>

        <div>
          <Select {...register('state')}>
            <option value=''>Estado:</option>
            {states.map((state: any) => {
              return (
                <option key={state.id} value={state.id}>
                  {state.estado}
                </option>
              );
            })}
          </Select>
          {/* <p>{errors.state?.message}</p> */}
        </div>

        <div>
          <Input
            type='text'
            id='ticketNumber'
            placeholder='Folio del ticket (21 dígitos)'
            {...register('ticketNumber')}
          />
          {/* <p>{errors.ticketNumber?.message}</p> */}
        </div>

        <DragNDropArea onDragOver={handleDragOver} onDrop={handleDrop}>
          {!imagePreview && (
            <Label htmlFor='image'>
              <ImgLabel src={DragIcon} alt='Icono de arrastra tu ticket' />
              &nbsp;o arrastra aquí la foto de tu ticket
            </Label>
          )}
          {imagePreview && (
            <img
              src={imagePreview}
              alt=''
              style={{ maxWidth: '100px', maxHeight: '100px' }}
            />
          )}
          <input
            type='file'
            id='image'
            accept='image/*'
            style={{ display: 'none' }}
            onChange={handleImageChange}
          />
          {/* <p>{errors.image?.message}</p> */}
        </DragNDropArea>

        <TermsWrapper>
          <input
            type='checkbox'
            id='termsAndConditions'
            {...register('termsAndConditions')}
          />
          <TermsLabel htmlFor='termsAndConditions'>Acepto</TermsLabel>
          <TermsDownload href='/bases-3.pdf' target='blank'>
            Términos y condiciones
          </TermsDownload>
          {/* <p>{errors.termsAndConditions?.message}</p> */}
        </TermsWrapper>

        {errors && (
          <ErrorsWrapper>
            {Object.entries(errors)?.map(([field, error]: any, index: any) => (
              <FormErrors key={index}>{`Error: ${error.message}`}</FormErrors>
            ))}
          </ErrorsWrapper>
        )}
        <WrapperButton>
          <SubmitButton disabled type='submit'>Enviar</SubmitButton>
        </WrapperButton>
      </FormWrapper>
    </MainLayout>
  );
};

export default Form;
