import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const ImagesWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  height: 300px;
  img:nth-child(1) {
    width: 72.22px;
    height: 142.39px;
  }
  img:nth-child(2) {
    width: 25px;
    height: 25px;
  }
  img:nth-child(3) {
    width: 105px;
    height: 142.39px;
  }
  img:nth-child(4) {
    width: 25px;
    height: 25px;
  }
  img:nth-child(5) {
    width: 72.22px;
    height: 90px;
  }
`;

export const Step = styled.p`
  font-size: 25px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0 24px 0 24px;
  color: #ffffff;
  span {
    font-weight: 700;
  }
`;
