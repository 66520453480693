import { Route, Routes } from 'react-router-dom';
import { pages } from './routes/pages';
import Home from './pages/Home';
import Dashboard from './components/Dashboard';
import Login from './components/Login';

function App() {
  return (
    <Routes>
      <Route path={pages.home} element={<Home />} />
      <Route path={pages.dashboard} element={<Dashboard />} />
      <Route path={pages.login} element={<Login />} />
    </Routes>
  );
}

export default App;
