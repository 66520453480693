import React from 'react';
import Modal from '@mui/material/Modal';
import {
  Wrapper,
  ButtonRed,
  MainTitle,
  ContentWrapper,
  ImgBull,
} from './styled';
import MainLogo from '../../assets/main-logo.png';
import Bull from '../../assets/bull-modal.png';

const ModalStart = ({ open, handleClose }: any) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Wrapper>
        <img src={MainLogo} loading='eager' />
        <ContentWrapper>
          <MainTitle>
            NOS ENCANTA AÑADIR UNA COPA MÁS A NUESTRA MESA, PERO SOLO SI ERES
            MAYOR DE EDAD.
          </MainTitle>
          <ButtonRed onClick={handleClose}>Lo soy, brindemos</ButtonRed>
          <ButtonRed href='https://www.alcoholinformate.org.mx/'>
            No, me falta un poquito
          </ButtonRed>
        </ContentWrapper>
        <ImgBull src={Bull} loading='eager' />
      </Wrapper>
    </Modal>
  );
};

export default ModalStart;
