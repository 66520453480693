import axios from 'axios';
import { api } from '../routes/api';

export const getQuestions = async () => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios(api.question, { headers });
  const data = response.data;
  return data;
};

export const deleteQuestion = async (id: number) => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const buildObject = { ids: [id] };
  const response = await axios.post(api.deleteQuestion, buildObject, {
    headers,
  });
  const data = response.data;
  return data;
};

export const createQuestion = async (question: any) => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(api.createQuestion, question, {
    headers,
  });
  const data = response.data;
  return data;
};

export const getLeaderBoard = async () => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios(api.getLeaderboard, { headers });
  const data = response.data;
  return data;
};

export const getLeaderBoardPrices = async () => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios(api.getLeaderboardPrices, { headers });
  const data = response.data;
  return data;
};

export const getParticipants = async () => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios(api.getParticipants, { headers });
  const data = response.data;
  return data;
};

export const approveParticipant = async (id: number) => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const dataObject = {
    ids: [id],
    aprobado: true,
  };
  const response = await axios.post(api.approveParticipant, dataObject, {
    headers,
  });
  const data = response.data;
  return data;
};

export const rejectParticipant = async (id: number) => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const dataObject = {
    ids: [id],
    aprobado: false,
  };
  const response = await axios.post(api.approveParticipant, dataObject, {
    headers,
  });
  const data = response.data;
  return data;
};

export const updateCounter = async (counter: number) => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(
    api.updateTriviaCounter,
    { contador: counter },
    {
      headers,
    }
  );
  const data = response.data;
  return data;
};

export const getCounter = async () => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios(api.getTriviaCounter, { headers });
  const data = response.data;
  return data;
};

export const getImage = async (gcsfile: unknown) => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(
    api.getImage,
    { gcsfile },
    {
      headers,
      responseType: 'blob',
    }
  );
  const data = response.data;
  return data;
};

export const updateTimer = async (counter: number) => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios.post(
    api.timer,
    { contador: counter },
    {
      headers,
    }
  );
  const data = response.data;
  return data;
};

export const getTimer = async () => {
  const token = localStorage.getItem('sdt');

  const headers = {
    Authorization: `Bearer ${token}`,
  };
  const response = await axios(api.timer, { headers });
  const data = response.data;
  return data;
};